<template>
    <div v-if="test_assigned == 0 || progress == 0" class="new_message">
        <span class="bi-patch-exclamation-fill patch" :style="currentVars"></span>
        <b class="hide" :style="currentVars">{{ test_assigned == 0 ? 'No assigned tests' : 'No completed tests' }}</b>
    </div>
    <div style="height: 15%;">
        <h2 class="vertical-center">{{ test_name }}</h2>
    </div>
    <div class="circle-container">
        <circle-progress :percent="progress" :border-width="20" :border-bg-width="20" :transition="800" :viewport="true"
            :show-percent="true" :fill-color="determineFill(progress)"
            :empty-color="determineEmpty(test_assigned, progress)" :unit="'% User Completion'" class="center" />
    </div>
    <p>
        {{ test_info }}
    </p>
    <p>
        <button type="button" class="btn" :class="determineButton(test_assigned, progress)"
            @click="$emit('redirectToProgress')">
            Review
        </button>
    </p>
</template>

<script>
import CircleProgress from "../components/CircleProgress.vue"
export default {
    name: 'AdminCard',
    components: {
        CircleProgress
    },
    emits: ['redirectToProgress'],
    props: {
        test_name: {
            type: String
        },
        test_info: {
            type: String
        },
        test_assigned: {
            type: Number,
            default: 0
        },
        test_completed: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            progress: 0
        }
    },
    methods: {
        determineButton(assign, prog) {
            if (assign == 0) {
                return ['btn-warning']
            } else if (prog >= 100)
                return ['btn-success']
            else {
                return ['btn-primary']
            }
        },
        determineEmpty(assign, prog) {
            if (assign == 0) {
                return '#ffefbd'
            } else if (prog >= 100) {
                return '#35db61'
            } else {
                return '#9cc2ff'
            }
        },
        determineFill(prog) {
            if (prog >= 100)
                return '#35db61'
            else {
                return "#3d44ff"
            }
        },
        calculateProgress() {
            const progress = (this.test_completed / this.test_assigned) * 100
            if (progress > 100) { // Error with calculation, temporary bugfix
                this.progress = 100
            }
            this.progress = progress
        }
    },
    computed: {
        currentVars() {
            return {
                color: this.test_assigned == 0 ? '#ffc107' : '#0d6efd'
            }
        },
    },
    mounted() {
        if (this.test_completed != 0 && this.test_assigned != 0) {
            this.calculateProgress()
        }
    }
}
</script>

<style scoped>
p {
    color: hsl(0, 0%, 35%);
    font-size: 14px;
    font-style: oblique;
}

h2 {
    color: hsl(0, 0%, 35%);
    font-size: 22px;
    font-weight: bold;
}

.circle-container {
    color: hsl(0, 0%, 35%);
    font-size: 15px;
    font-weight: bold;
    height: 70%;
}

.btn-warning {
    color: #fff
}

.new_message {
    position: absolute;
    top: 1em;
    left: 1em;
}

.hide {
    visibility: hidden;
    padding: 0 1em 0 1em;
}

.patch {
    font-size: 24px;
    color: #198754;
    cursor: pointer;
}

.patch:hover+.hide {
    visibility: visible;
    color: #198754;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: 1vw;
    padding: 1vh 1vw 1vh 1vw;
}
</style>