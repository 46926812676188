<template>
    <div class="page">
        <vue-basic-alert :duration="300" :closeIn="7000" ref="alert" />

        <div v-if="testList.selectedTestNew">
            <TestStart :test_name="testList.newTests[givenTestIndex].name"
                :test_info="testList.newTests[givenTestIndex].info" :test_due="testList.newTests[givenTestIndex].due"
                :test_prog="testList.newTests[givenTestIndex].questions.length" @startTest="createSession(
                    testList.newTests[givenTestIndex].due,
                    testList.newTests[givenTestIndex].test,
                    testList.newTests[givenTestIndex].retry
                )" />
        </div>
        <div v-else-if="!startedTest">
            <TestStart :test_name="testList.tests[givenTestIndex].test.name"
                :test_info="testList.tests[givenTestIndex].test.info" :test_due="testList.tests[givenTestIndex].due"
                :test_prog="testList.tests[givenTestIndex].progress.length" @startTest="startTest()" />
        </div>
        <div v-else-if="endTest">
            <TestEnd @cancelSubmission="endTest = false" @confirmSubmission="submitTest"
                :test_flags="tstore.menu.map(question => question.flagged)" />
        </div>
        <div v-else-if="!isFetching" class="flex-container">
            <loading v-model:active="imagesLoading" :can-cancel="false" :is-full-page="false" :loader="'dots'"
                :color="'#ff0000'" :height="200" :width="200" />

            <div class="sidebar" style="overflow-x: hidden;">
                <SidebarMenu :menu="tstore.menu" :relative="true" @item-click="onItemClick" style="min-height: 92vh;" />
            </div>

            <div class="test-area">
                <Transition name="high" appear>
                    <div>
                        <div class="container-fluid">
                            <div class="row justify-content-center">
                                <div class="col-sm-4">
                                    <button type="button" class="btn btn-dark" @click="router.push('/dashboard')"
                                            style="margin:0 0.5em 1em 0.5em; float:left">Exit Test</button>
                                    <button v-if="tstore.menu.length >= 2 && parseInt(tstore.current_question, 10) !== 0"
                                        type="button" class="btn btn-success" @click="previousProbe"
                                        style="margin:0 0.5em 1em 0.5em;">Previous Q{{ parseInt(tstore.menu[parseInt(tstore.current_question, 10) + 1].index) }}</button>
                                </div>
                                <div class="col-sm-4">
                                    <h2>
                                        {{ tstore.menu[parseInt(tstore.current_question, 10) + 1].title }}
                                        <i class="bi" @click="updateFlagged()"
                                            :class="tstore.menu[parseInt(tstore.current_question, 10) + 1].flagged ? 'bi-flag-fill' : 'bi-flag'"
                                            :style="{ 'font-size': '22.5px', 'cursor': 'pointer', 'color': tstore.menu[parseInt(tstore.current_question, 10) + 1].flagged ? '#ffbf00' : '#404040' }"
                                            title="Flag a question, useful for noting questions to return to.">
                                        </i>
                                    </h2>
                                </div>

                                <div class="col-sm-4">
                                    <div v-if="originalOTOList != ''">
                                        <button type="button" class="btn btn-secondary" @click="disableOneToOne"
                                            style="margin:0 0.5em 1em 0.5em;">Cancel 1:1 View</button>
                                        <button type="button" class="btn btn-success" @click="nextProbe"
                                            style="margin:0 0.5em 1em 0.5em;float:right">Next Q{{ parseInt(tstore.menu[parseInt(tstore.current_question, 10) + 1].index) + 2 }}</button>
                                    </div>
                                    <div v-else-if="questionState[tstore.current_question].candidates.length > 0 && !checkOneToOneDefault()"
                                        >
                                        <div class="flex-container form-check form-switch" style="float:left">
                                            <input v-model="toggleChoice" class="form-check-input"
                                                style="height: 25px; width: 50px; position: relative; display: inline-block;"
                                                type="checkbox" role="switch" />
                                            <label style="padding-top: 7px; margin-left: 10px;" class="form-check-label"
                                                for="flexSwitchCheckDefault">
                                                <p class="option-label">View Candidates Individually</p>
                                            </label>
                                        </div>
                                        <button v-if="parseInt(tstore.current_question, 10) + 2 !== tstore.menu.length"
                                            type="button" class="btn btn-success" @click="nextProbe"
                                            style="margin:0 0.5em 1em 0.5em; float:right">Next Q{{ parseInt(tstore.menu[parseInt(tstore.current_question, 10) + 1].index) + 2 }}</button>
                                    </div>
                                    <div v-else-if="parseInt(tstore.current_question, 10) + 2 !== tstore.menu.length">
                                        <button type="button" class="btn btn-success" @click="nextProbe"
                                            style="margin:0 0.5em 1em 0.5em;">Next Q{{ parseInt(tstore.menu[parseInt(tstore.current_question, 10) + 1].index) + 2 }}</button>
                                        <button
                                            v-if="questionState.every((question) => question.candidates.length === 0) && questionState.length - 1 != tstore.current_question"
                                            type="button" style="margin:0 0.5em 1em 0.5em; float:right" class="btn btn-primary submit" @click="toggleEndTest"
                                            :title="!questionState.every((question) => question.candidates.length === 0)?'Cannot submit when questions are unanswered!':'Submit your test attempt.'">
                                            Submit Test
                                        </button>
                                    </div>
                                    <div v-else>
                                        <button type="button" class="btn btn-primary submit" style="margin: 0 0.5em 1em 0.5em; float:right" @click="toggleEndTest"
                                            :title="!questionState.every((question) => question.candidates.length === 0)?'Cannot submit when questions are unanswered!':'Submit your test attempt.'"
                                            :disabled="!questionState.every((question) => question.candidates.length === 0)">
                                            Submit Test
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!toggleChoice" class="answer-container justify-content-center">
                            <div class="row">
                                <div class="col">
                                    <ViewerTest ref="viewer" :options="options"
                                        :images="[imageReferences[questionState[tstore.current_question].probe]]"
                                        class="viewer" @inited="inited">
                                        <template #default="scope">
                                            <img v-for="id in scope.images" class="image" :key="`PROBE${id}`" :src="id"
                                                :data-source="id" alt="probe">
                                        </template>
                                    </ViewerTest>
                                    <p class="option-label" style="margin-top: 15px;">Probe</p>
                                </div>

                                <div class="col" style="padding-left: 30px;">
                                    <div v-if="!candidateCompleted">
                                        <div class="row justify-content-center" style="padding-bottom: 1.5em;">
                                            <div class="col-auto" v-if="!confirmMassAssign">
                                                <p class="option-label">
                                                    Number of Images per Row
                                                </p>
                                                <div style="margin-bottom: 20px;">
                                                    <button type="button" class="btn btn-danger btn-sm"
                                                        @click="decreaseTiles" title="Decrease Number of Images per Row"
                                                        :disabled="tiles == 2"
                                                        style="margin: 0 20px; width: 30px;">-</button>
                                                    <input disabled v-model.number="tiles" class="bg-light"
                                                        style="height: 30px; width: 30px; text-align: center; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 5px; font-size: 14px; font-weight: bold; color: rgba(0, 0, 0, 0.8)" />
                                                    <button type="button" class="btn btn-danger btn-sm"
                                                        @click="increaseTiles" title="Increase Number of Images per Row"
                                                        :disabled="tiles == 7"
                                                        style="margin: 0 20px; width: 30px;">+</button>
                                                </div>
                                            </div>

                                            <div class="center-screen col-auto" v-if="confirmMassAssign">
                                                <p>
                                                    Are you sure you want to set all remaining candidates as Non-Match?
                                                </p>
                                                <div style="display:inline-block;">
                                                    <button type="button" class="btn btn-secondary btn-sm "
                                                        style="margin: 0 60px;" @click="confirmMassAssign = false">
                                                        Cancel
                                                    </button>
                                                    <button type="button" class="btn btn-success btn-sm"
                                                        style="margin: 0 60px;" @click="setAsNonMatch">
                                                        Confirm
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-auto" v-else>
                                                <p class="option-label">
                                                    Mass Assign Candidates
                                                </p>
                                                <button type="button" class="btn btn-danger btn-sm"
                                                    @click="confirmMassAssign = true"
                                                    title="Set All Candidates as Non-Match">Set Non-Match</button>
                                            </div>
                                        </div>

                                        <div class="row-auto" style="overflow: auto; min-height: 40vmin;">
                                            <DraggableImages @draggableRemove="completedPopUp"
                                                :images="questionState[tstore.current_question].candidates" :tiles="tiles"
                                                :imageBlobs="imageReferences"
                                                @imageSelected="(image) => triggerOneToOne(image, 'candidates')"
                                                :disable_viewer="true" :style="{
                                                    filter: confirmMassAssign ? 'blur(0.25rem)' : 'blur(0)',
                                                    height: '20vh'
                                                }" />
                                        </div>
                                        <p class="option-label" style="margin-top: 15px;">Drag candidates into the boxes below to categorise them</p>
                                    </div>

                                    <!-- End of Question -->
                                    <div v-if="questionState[tstore.current_question].candidates.length === 0">
                                        <div class="col">
                                            <h2 class="answer-box-header" style="padding: 10px; color: #198754">
                                                Match
                                            </h2>
                                            <div class="answer-box pos-answer-box">
                                                <DraggableImages @draggable-add="updateBins('pos')"
                                                    :images="questionState[tstore.current_question]['pos']" :tiles="3"
                                                    :img_width="'75px'" :imageBlobs="imageReferences"
                                                    @imageSelected="(image) => triggerOneToOne(image, 'pos')"
                                                    :disable_viewer="true" />
                                            </div>
                                            <h2 class="answer-box-header"
                                                style="padding: 10px; margin-top: 20px; color: rgb(228, 112, 10)">
                                                Inconclusive
                                            </h2>
                                            <div class="answer-box inc-answer-box">
                                                <DraggableImages @draggable-add="updateBins('inc')"
                                                    :images="questionState[tstore.current_question]['inc']" :tiles="3"
                                                    :img_width="'75px'" :imageBlobs="imageReferences"
                                                    @imageSelected="(image) => triggerOneToOne(image, 'inc')"
                                                    :disable_viewer="true" />
                                            </div>
                                            <h2 class="answer-box-header"
                                                style="padding: 10px; margin-top: 20px; color: rgb(94, 122, 148)">
                                                Non-Match
                                            </h2>
                                            <div class="answer-box neg-answer-box">
                                                <DraggableImages @draggable-add="updateBins('neg')"
                                                    :images="questionState[tstore.current_question]['neg']" :tiles="3"
                                                    :img_width="'75px'" :imageBlobs="imageReferences"
                                                    @imageSelected="(image) => triggerOneToOne(image, 'neg')"
                                                    :disable_viewer="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="toggleChoice && questionState[tstore.current_question].candidates.length != 0"
                            style="padding-top: 0;">
                            <div class="row justify-content-center">
                                <ComparisonView :probe="[questionState[tstore.current_question].probe]"
                                    :candidates="[questionState[tstore.current_question].candidates[0]]"
                                    :baseImageURL="baseImageURL" ref="comparisonviewer" />

                                <div class="row" style="margin-top: 30px; display:inline-block;">
                                    <button type="button" class="btn btn-non-match col-2" @click="moveCandidate('nonmatch')"
                                        style="margin: 0 60px; font-size: large;" :disabled="preventButtonSpam">
                                        Non-Match
                                    </button>

                                    <button type="button" class="btn btn-inconclusive col-2"
                                        @click="moveCandidate('inconclusive')"
                                        style="margin: 0 60px; font-size: large; border: none;"
                                        :disabled="preventButtonSpam">
                                        Inconclusive
                                    </button>

                                    <button type="button" class="btn btn-success col-2" @click="moveCandidate('match')"
                                        style="margin: 0 60px; font-size: large;" :disabled="preventButtonSpam">
                                        Match
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-if="questionState[tstore.current_question].candidates.length > 0 && !checkOneToOneDefault()" class="row"
                            style="margin-top: 30px;">
                            <div class="row">
                                <h2 class="col answer-box-header" style="color: rgb(94, 122, 148)">
                                    Non-Match
                                </h2>
                                <h2 class="col answer-box-header" style="color: rgb(228, 112, 10)">
                                    Inconclusive
                                </h2>
                                <h2 class="col answer-box-header" style="color: #198754">
                                    Match
                                </h2>
                            </div>

                            <div class="row">
                                <div class="col answer-box neg-answer-box">
                                <DraggableImages @draggable-add="updateBins('neg')"
                                        :images="questionState[tstore.current_question]['neg']" :tiles="3"
                                        :img_width="'75px'" :imageBlobs="imageReferences"
                                        @imageSelected="(image) => triggerOneToOne(image, 'neg')" :disable_viewer="true" />
                            </div>

                            <div class="col answer-box inc-answer-box">
                                <DraggableImages @draggable-add="updateBins('inc')"
                                        :images="questionState[tstore.current_question]['inc']" :tiles="3"
                                        :img_width="'75px'" :imageBlobs="imageReferences"
                                        @imageSelected="(image) => triggerOneToOne(image, 'inc')" :disable_viewer="true" />
                            </div>

                            <div class="col answer-box pos-answer-box">
                                <DraggableImages @draggable-add="updateBins('pos')"
                                        :images="questionState[tstore.current_question]['pos']" :tiles="3"
                                        :img_width="'75px'" :imageBlobs="imageReferences"
                                        @imageSelected="(image) => triggerOneToOne(image, 'pos')" :disable_viewer="true" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store.js';
</script>

<script>
import DraggableImages from "../components/DraggableImages.vue";
import ViewerTest from "../components/ViewerComp.vue"
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
import ComparisonView from "../components/ComparisonView.vue"
import TestStart from "../components/TestStart.vue"
import TestEnd from "../components/TestEnd.vue"
import { SidebarMenu } from 'vue-sidebar-menu'
import { defineStore } from 'pinia'
import router from '@/router';
import axios from 'axios';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export const testStorage = defineStore('testStore', {
    state: () => ({
        questions: [],
        current_question: 0,
        menu: []
    }),
})

export default {
    name: 'TestPage',
    order: 1,
    components: {
        DraggableImages,
        ViewerTest,
        SidebarMenu,
        ComparisonView,
        TestStart,
        TestEnd,
        Loading
    },
    data() {
        return {
            givenTestIndex: null,
            testList: testListStore(),
            tstore: testStorage(),
            questionState: [],
            imageReferences: {},
            isFetching: false,
            candidateCompleted: false,
            toggleChoice: false,
            originalView: 'grid', // Defines what view type will render next
            originalOTOList: '', // Individual view is different to Inspect view
            originalViewChoice: false, // Maintaining view choice after 1:1 case
            startedTest: false,
            endTest: false,
            imagesLoading: true,
            preventButtonSpam: false,
            confirmMassAssign: false,
            tiles: 4,
            inspectImageIndex: 0,
            negBoxColour: 'rgba(0, 0, 0, 0.02)',
            incBoxColour: 'rgba(0, 0, 0, 0.02)',
            posBoxColour: 'rgba(0, 0, 0, 0.02)',
            toggleOptions: [
                'button',
                'navbar',
                'title',
                'toolbar',
                'tooltip',
                'movable',
                'zoomable',
                'rotatable',
                'scalable',
                'transition',
                'fullscreen',
                'keyboard',
            ],
            options: {
                inline: false,
                button: true,
                navbar: true,
                title: true,
                toolbar: {
                    oneToOne: true,
                    leftRotate: () => {
                        this.$viewer.rotate(10);
                    },
                    rightRotate: () => {
                        this.$viewer.rotate(-10);
                    },
                    reset: true,
                    customZoom: () => {
                        this.$viewer.zoom(1)
                    }
                },
                tooltip: true,
                movable: true,
                zoomable: true,
                rotatable: true,
                scalable: true,
                transition: false,
                fullscreen: true,
                keyboard: true,
                url: 'data-source',
            },
            questionStartTime: 0,
        }

    },
    methods: {
        updateFlagged() {
            this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].flagged = !this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].flagged
            if (this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].flagged) {
                this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].icon = "bi-flag-fill"
            } else if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].icon = 'bi-check-lg'
            } else {
                this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].icon = "bi-question-lg"
            }
        },
        elapsedStart() {
            this.questionStartTime = Date.now()
        },
        elapsedEnd() {
            const questionEndTime = Date.now()
            let elapsedTime = questionEndTime - this.questionStartTime
            return Math.ceil(elapsedTime /= 1000)
        },
        inited(viewer) {
            this.$viewer = viewer
        },
        indexInstance(index) {
            this.inspectImageIndex = index
        },
        toggleToolbar(toolbar) {
            this.options.toolbar = toolbar
        },
        increaseTiles() {
            // TODO -> Max should be the maximum number of images
            if (this.tiles < 7) {
                this.tiles++
            }

        },
        decreaseTiles() {
            if (this.tiles > 2) {
                this.tiles--
            }
        },
        setSidebarCheck() {
            if (this.questionState[this.tstore.current_question].candidates.length === 0 && !this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].flagged) {
                this.tstore.menu[parseInt(this.tstore.current_question, 10) + 1].icon = 'bi-check-lg'
            }
        },
        setAsNonMatch() {
            for (const obj of this.questionState[this.tstore.current_question].candidates) {
                this.questionState[this.tstore.current_question]['neg'].push(obj);
            }
            this.questionState[this.tstore.current_question].candidates = []
            this.candidateCompleted = true
            this.setSidebarCheck()
            this.popUpAlert()
            this.confirmMassAssign = false
        },
        toggleCandidateVisual() {
            this.candidateCompleted = this.questionState[this.tstore.current_question].candidates.length === 0
            if (this.candidateCompleted) {
                this.setSidebarCheck()
            }
        },
        triggerOneToOne(image, origin) {
            // Index of candidate in its original list (origin)
            const candidate_index = this.questionState[this.tstore.current_question][origin].findIndex(candidate => candidate == image)
            if (origin != 'candidates' || (candidate_index != 0 && origin == 'candidates')) { // Don't move if already first in candidates
                this.questionState[this.tstore.current_question][origin].splice(candidate_index, 1) // Remove from original list
                this.questionState[this.tstore.current_question].candidates.unshift(image) // Add to front of candidates
            }
            this.originalOTOList = origin // Enable 1:1 view flag
            this.toggleChoice = true // Enable inspect view
        },
        disableOneToOne() {
            this.questionState[this.tstore.current_question][this.originalOTOList].unshift(this.questionState[this.tstore.current_question].candidates[0])  // Move back to original list
            this.questionState[this.tstore.current_question].candidates.splice(0, 1) // Remove from candidates
            this.originalOTOList = '' // Disable 1:1 view flag
            if (this.originalView == 'grid') {
                this.toggleChoice = false //  
            } else if (this.originalView == 'inspect') {
                this.toggleChoice = true //
            }
        },
        previousProbe() {
            this.postQuestionResults()
            if (this.originalOTOList != '') {
                this.cancelOneToOneAlert()
                this.disableOneToOne()
            }
            this.tstore.current_question--
            if (this.checkOneToOneDefault()) {
                this.toggleChoice = true
            } else if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                this.toggleChoice = false
            } else if (this.originalView == 'grid') {
                this.toggleChoice = false
            } else if (this.originalView == 'inspect') {
                this.toggleChoice = true
            }
            this.toggleCandidateVisual()
        },
        nextProbe() {
            this.postQuestionResults()
            if (this.originalOTOList != '') {
                this.cancelOneToOneAlert()
                this.disableOneToOne()
            }
            this.tstore.current_question++
            if (this.checkOneToOneDefault()) {
                this.toggleChoice = true
            } else if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                this.toggleChoice = false
            } else if (this.originalView == 'grid') {
                this.toggleChoice = false
            } else if (this.originalView == 'inspect') {
                this.toggleChoice = true
            }
            this.toggleCandidateVisual()
        },
        shuffle(array) {
            let currentIndex = array.length, randomIndex;
            // While there remain elements to shuffle.
            while (currentIndex != 0) {
                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
            return array;
        },
        startTest() {
            this.startedTest = true // Hide TestStart page and show questions
            this.elapsedStart() // Start timer
        },
        checkOneToOneDefault() {
            return this.questionState[this.tstore.current_question]['pos'].length == 0 && this.questionState[this.tstore.current_question]['neg'].length == 0 && this.questionState[this.tstore.current_question]['inc'].length == 0 && this.questionState[this.tstore.current_question]['candidates'].length == 1
        },
        postQuestionResults(end = false) {
            axios(
                {
                    method: 'put',
                    url: `${process.env.VUE_APP_API}/session/tests/${this.testList.tests[this.givenTestIndex]["_id"]}/questions/${this.questionState[this.tstore.current_question].sessionID}?end=${end}`,
                    data: {
                        "pos": this.questionState[this.tstore.current_question]['pos'],
                        "neg": this.questionState[this.tstore.current_question]['neg'],
                        "inc": this.questionState[this.tstore.current_question]['inc'],
                        "elapse": this.elapsedEnd()
                    },
                    withCredentials: true,
                }
            )
                .then(() => this.elapsedStart()) // Reset the elapsed time
            this.testList.tests[this.givenTestIndex]['progress']

        },
        onItemClick(event, item) {
            // TODO - Disable currently selected menu button
            // this.tstore.menu[item.index].disabled = true
            // this.tstore.menu[item.current_question].disabled = false
            if (this.originalOTOList != '') {
                this.cancelOneToOneAlert()
                this.disableOneToOne()
            }
            this.postQuestionResults()
            this.tstore.current_question = item.index
            if (this.checkOneToOneDefault()) {
                this.toggleChoice = true
            } else if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                this.toggleChoice = false
                if (this.originalView == 'inspect') {
                    this.originalViewChoice = true
                }

            } else if (this.originalView == 'grid') {
                this.toggleChoice = false
            } else if (this.originalView == 'inspect') {
                this.toggleChoice = true
            }

            this.toggleCandidateVisual()
            this.$forceUpdate()
        },
        moveCandidate(whichList) {
            const pushNextQuestion = this.checkOneToOneDefault()
            this.preventButtonSpam = true // Prevent users from spamming the decision buttons while processing
            this.$refs.comparisonviewer.pushCandidateViewer(whichList)
            setTimeout(
                () => {
                    // Start at inspectImageIndex (0), & delete 1
                    const candidateChoice = this.questionState[this.tstore.current_question].candidates.splice(this.inspectImageIndex, 1)
                    if (whichList === 'nonmatch') {
                        this.questionState[this.tstore.current_question]['neg'].push(candidateChoice[0])
                    } else if (whichList === 'match') {
                        this.questionState[this.tstore.current_question]['pos'].push(candidateChoice[0])
                    } else {
                        this.questionState[this.tstore.current_question]['inc'].push(candidateChoice[0])
                    }
                    this.preventButtonSpam = false

                    if (this.originalOTOList != '') {
                        this.originalOTOList = ''
                        if (this.originalView == 'grid') {
                            this.toggleChoice = false //  
                        } else if (this.originalView == 'inspect') {
                            this.toggleChoice = true //
                        }
                    }

                    if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                        this.$refs.alert.showAlert(
                            'success',
                            `Sorted all of the candidates for the given probe in Question ${parseInt(this.tstore.current_question, 10) + 1}.`,
                            `Completed Question ${parseInt(this.tstore.current_question, 10) + 1}`,
                            {
                                iconSize: 35,
                                iconType: 'solid',
                                position: 'bottom right'
                            }
                        )
                        this.toggleChoice = false
                        this.originalViewChoice = true
                        this.toggleCandidateVisual()
                        this.setSidebarCheck()
                    }

                    if (parseInt(this.tstore.current_question, 10) + 2 !== this.tstore.menu.length && pushNextQuestion) {
                        this.nextProbe()
                    }

                },
                600
            );
        },
        submitTest() {
            this.postQuestionResults(true)
            // const findTest = (test) => test.id === this.testList.selectedTest
            // this.testList.tests[this.testList.tests.findIndex(findTest)].status = 'completed'
            router.push('/dashboard')
        },
        popUpAlert() {
            this.$refs.alert.showAlert(
                'success', // There are 4 types of alert: success, info, warning, error
                `Sorted all of the candidates for the given probe in Question ${parseInt(this.tstore.current_question, 10) + 1}.`, // Message of the alert
                `Completed Question ${parseInt(this.tstore.current_question, 10) + 1}`, // Header of the alert
                {
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'bottom right'
                } // Position of the alert 'bottom right', 'top left', 'bottom left', 'bottom right'
            )
        },
        cancelOneToOneAlert() {
            this.$refs.alert.showAlert(
                'info', 
                '',
                `Cancelled One-to-One View`,
                {
                    iconSize: 35,
                    iconType: 'solid',
                    position: 'bottom right'
                } 
            )
        },
        completedPopUp() {
            if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                this.toggleCandidateVisual()
                this.popUpAlert()
            }
        },
        toggleEndTest() {
            if (this.questionState.every((question) => question.candidates.length === 0)) {
                this.endTest = !this.endTest
            } else {
                // const notAnswered = this.testList[this.testList.selectedTest].forEach((question, index) => question.candidates.length !== 0? )
                this.$refs.alert.showAlert(
                    'warning', // There are 4 types of alert: success, info, warning, error
                    `Cannot submit a test with incomplete questions! Complete questions: ${this.questionState.reduce((arr, e, i) => ((e.candidates.length !== 0) && arr.push(i + 1), arr), [])}`, // Message of the alert
                    'Incomplete Test', // Header of the alert
                    {
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'bottom right'
                    } // Position of the alert 'bottom right', 'top left', 'bottom left', 'bottom right'
                )
            }

        },
        updateBins(whichList) {
            // TODO! Functionise the below. 
            const oldColor = 'rgba(0, 0, 0, 0.02)'
            if (whichList === 'neg') {
                this.negBoxColour = 'rgba(94, 122, 148, 0.5)'
                setTimeout(
                    () => {
                        this.negBoxColour = oldColor
                    },
                    600
                );
            } else if (whichList === 'pos') {
                this.posBoxColour = 'rgba(2, 245, 67, 0.5)'
                setTimeout(
                    () => {
                        this.posBoxColour = oldColor
                    },
                    600
                );
            } else if (whichList === 'inc') {
                this.incBoxColour = 'rgba(236, 119, 40, 0.5)'
                setTimeout(
                    () => {
                        this.incBoxColour = oldColor
                    },
                    600
                );
            }
        },
        keyPressEvent(event) {
            if (this.toggleChoice && this.questionState[this.tstore.current_question].candidates.length != 0 && !this.preventButtonSpam) {
                if (event.code == 'KeyN') {
                    this.moveCandidate('nonmatch')
                } else if (event.code == 'KeyM') {
                    this.moveCandidate('match')
                } else if (event.code == 'KeyI') {
                    this.moveCandidate('inconclusive')
                }
            }
            // if (event.code == 'ArrowLeft' && this.tstore.menu.length >= 2 && parseInt(this.tstore.current_question, 10) !== 0) {
            //     this.previousProbe()
            // }
            // if (event.code == 'ArrowRight' && parseInt(this.tstore.current_question, 10) + 2 !== this.tstore.menu.length) {
            //     this.nextProbe()
            // }
        },
        async getBase64(imageRef, query_hack = 0) {
            // TODO - We randomly get a CORS issue for some images, requesting it was a different query param gets past this.
            axios.get(`${process.env.VUE_APP_API}/session/images/${imageRef}?it=${query_hack}`,
                {
                    withCredentials: true,
                    responseType: "blob"
                }
            )
                .then(response => {
                    this.imageReferences[imageRef] = URL.createObjectURL(response.data)
                })
                .catch(() => this.getBase64(imageRef, query_hack + 1))
        },
        createSession(due, test_id, retry) {
            this.isFetching = true
            axios(
                {
                    method: 'post',
                    url: `${process.env.VUE_APP_API}/session/test/?due=${due}&test_id=${test_id}&retry=${retry}`,
                    data: {},
                    withCredentials: true,
                }
            )
                .then(results => {
                    const session_id = results.data['_id']
                    const newTestIndex = this.givenTestIndex
                    axios.get(`${process.env.VUE_APP_API}/session/tests/${session_id}`, { withCredentials: true })
                        .then(results => {
                            this.testList.tests.push(results.data)

                            // Add the test session in pinia's test list
                            this.testList.$patch({
                                selectedTest: session_id,
                                selectedTestNew: false
                            });

                            this.initialiseTest(true, newTestIndex)

                            // Pop the current index from this.testList.newTests
                            // this.testList.newTests.splice(newTestIndex, 1) 
                        })



                })
        },
        initialiseTest(override_start_screen = false, oldIndex = 0) {
            this.questionState = []
            this.tstore.menu = [
                {
                    header: 'Test Navigation',
                    hiddenOnCollapse: true
                }
            ]
            this.tstore.current_question = 0
            this.givenTestIndex = this.testList.tests.findIndex((test) => test['_id'] === this.testList.selectedTest)

            for (const key in this.testList.tests[this.givenTestIndex].progress) {
                var current_index = (parseInt(key, 10) + 1);
                this.tstore.menu.push(
                    {
                        title: `Question ${current_index}`,
                        index: key,
                        icon: 'bi-question-lg',
                        flagged: false
                        // disabled: (current_index===1)
                    }
                );
            }

            const promises = []
            this.questionState = Array.apply(null, Array(this.testList.tests[this.givenTestIndex]['progress'].length)).map(function () { })
            for (const question of this.testList.tests[this.givenTestIndex]['progress']) {
                const promise = axios.get(
                    `${process.env.VUE_APP_API}/session/test/${this.testList.tests[this.givenTestIndex].test.id}/questions/${question.question}`,
                    {
                        withCredentials: true
                    }
                )
                    .then(results => {
                        // Remove elements from candidates if already in 'inc', 'neg', or 'pos'.
                        let questionCandidates = results.data.candidates
                        let organisedCandidates = question.pos.concat(question.neg, question.inc)
                        let filteredCandidates = questionCandidates.filter((el) => !organisedCandidates.includes(el))
                        // All question image ID's for requesting image references
                        const allImages = organisedCandidates.concat(filteredCandidates, [results.data.probe])
                        allImages.forEach(element => this.imageReferences[element] = null)
                        this.questionState[results.data.index] = {
                            sessionID: question.question,
                            probe: [results.data.probe],
                            candidates: this.shuffle(filteredCandidates),
                            inc: question.inc,
                            neg: question.neg,
                            pos: question.pos
                        }

                    })
                promises.push(promise)
            }

            Promise.all(promises)
                .then(() => {
                    for (const question_index in this.questionState) {
                        // Set 'completed' questions with checkmarks.
                        if (this.questionState[question_index].candidates.length === 0) {
                            this.tstore.menu[parseInt(question_index, 10) + 1].icon = 'bi-check-lg'
                        }
                    }

                    // Set the index to the last question
                    const foundIndex = this.questionState
                        .map(question => question.candidates.length)
                        .findIndex(candidate_length => candidate_length > 0)
                    if (foundIndex != -1) {
                        this.tstore.current_question = foundIndex
                    }

                    if (override_start_screen) { 
                        this.testList.newTests.splice(oldIndex, 1)
                    }
                    if (this.checkOneToOneDefault()) {
                        this.toggleChoice = true
                    }
                    if (this.questionState[this.tstore.current_question].candidates.length === 0) {
                        this.toggleCandidateVisual()
                    }
                    const image_promises = []
                    for (let imageRef of Object.keys(this.imageReferences)) {
                        const image_promise = this.getBase64(imageRef)
                        image_promises.push(image_promise)
                    }
                    Promise.all(image_promises)
                        .then(() => {
                            this.imagesLoading = false
                            if (this.testList.tests[this.givenTestIndex]['status'] === 1 | override_start_screen) {
                                this.startTest()
                                this.isFetching = false
                            }
                        })
                })
            
            window.addEventListener('keyup', (event) => {this.keyPressEvent(event)})
        }
    },
    created() {
        if (this.testList.selectedTestNew) {
            this.givenTestIndex = this.testList.newTests.findIndex((test) => test['test'] === this.testList.selectedTest)
        } else {
            this.initialiseTest()
        }

    },
    watch: {
        toggleChoice(newToggle) {
            if (this.checkOneToOneDefault()) { // if the Question has 1 candidate
                // pass, do not update originalView
            } else if (!newToggle && this.originalViewChoice) {
                this.originalView = 'inspect'
                this.originalViewChoice = false
            } else if (newToggle && this.originalOTOList == '') { // if toggled ON by user
                this.originalView = 'inspect'
            } else if (!newToggle && this.originalOTOList == '') { // if toggled OFF by user
                this.originalView = 'grid'
            }

        },
    },
    computed: {
        baseImageURL() {
            return `${process.env.VUE_APP_API}/session/images/`
        }
    },
    beforeUnmount() {
        if (!this.endTest & !this.testList.selectedTestNew) {
            this.postQuestionResults()
            if (this.originalOTOList != '') {
                this.disableOneToOne()
            }
        }
        if (this.testList.selectedTestNew) {
            this.testList.$patch({
                selectedTestNew: false
            });
        }
    }
}
</script>

<style lang="scss" scoped>
h2 {
    margin-bottom: 30px;
    font-weight: bold;
}

.col {
    padding: 0;
    margin: 0;
}

.option-label {
    font-size: 14px;
    color: hsl(0, 0%, 40%);
}

.image {
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.3);
    height: 50vmin;
}

.answer-box {
    overflow: auto;
    margin: 0 2vh;
    border-radius: 10px;
    border: solid 2px rgba(0, 0, 0, 0.02);
}

.answer-box-header {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    margin: 0 2vh 10px 2vh;
    font-size: 20px;
    border: solid 2px rgba(0, 0, 0, 0.02);
}

.neg-answer-box {
    background-color: v-bind(negBoxColour);
}

.inc-answer-box {
    background-color: v-bind(incBoxColour);
}

.pos-answer-box {
    background-color: v-bind(posBoxColour);
}

.flex-container {
    display: flex;
}

.inspect {
    height: 500px;
    margin: 1em 1em 1em 1em;
    margin-top: 0;
}

.test-area {
    padding: 0 40px 40px 40px;
    margin: 0;
    width: 100%;
}

.answer-container {
    background-color: rgba(0, 0, 0, 0.02);
    padding: 40px;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    border: solid 2px rgba(0, 0, 0, 0.02);
}

.blurContent {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.center-screen {
    padding: 20px;
    z-index: 10;
    border-radius: 10px;
    background-color: #fff;
}

.finalise-button {
    margin-top: 20px;
}

.form-check-input:checked {
    background-color: #dc3545;
    border: none;
}

.form-check-input:focus {
    box-shadow: 0 0 1px #dc3545;
    border: none;
}

.btn-non-match {
    --bs-btn-color: #fff;
    --bs-btn-bg: rgb(107, 139, 168);
    --bs-btn-border-color: rgb(107, 139, 168);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: rgb(85, 110, 134);
    --bs-btn-hover-border-color: rgb(85, 110, 134);
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: rgb(85, 110, 134);
    --bs-btn-active-border-color: rgb(85, 110, 134);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: rgb(94, 122, 148);
    --bs-btn-disabled-border-color: rgb(94, 122, 148);
}

.btn-inconclusive {
    --bs-btn-color: #fff;
    --bs-btn-bg: #f4a460;;
    --bs-btn-border-color: #f4a460;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #d18e54;
    --bs-btn-hover-border-color: #d18e54;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #e79d5c;
    --bs-btn-active-border-color: #e79d5c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f4a460;
    --bs-btn-disabled-border-color: #f4a460;
}

.submit:disabled{
    --bs-btn-disabled-bg: #93bdfa;
    --bs-btn-disabled-border-color: #93bdfa;
    pointer-events: auto;
}
</style>
