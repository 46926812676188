<template>
    <Transition name="high" appear>
        <div class="start-container">
            <div class="row">
                <h2 style="margin-bottom: 3vh;">
                    {{ test_name }}
                </h2>
            </div>
            <div class="row">
                <div class="col">
                    <h2>
                        Description
                    </h2>
                    <p style="overflow: auto; font-size: 14px;">
                        {{ test_info }}
                    </p>
                </div>
                <div class="col">
                    <h2>
                        Due Date
                    </h2>
                    <p style="font-size: 14px;">
                        {{ new Date(test_due) }}
                    </p>
                    <Calendar :attributes="[
                        {
                            highlight: {
                                color: 'blue',
                                fillMode: 'solid',
                            },
                            dates: new Date(test_due),
                            popover: {
                                label: `Due Date for:
                                        ${test_name}`
                            }
                        },
                        {
                            key: 'today',
                            highlight: {
                                color: 'red',
                                fillMode: 'solid',
                                contentClass: 'italic',
                            },
                            dates: new Date(),
                            popover: {
                                label: `Today's Date`
                            }
                        }
                    ]" />

                </div>
                <div class="row" style="margin-top: 2em;">
                    <div class="col">
                        <p>
                            This test will contain a total of <a style="color: hsl(0, 0%, 25%);">{{ `${test_prog}
                                                            ${test_prog === 1 ? "question" : "questions"}` }}.</a>
                        </p>
                        <p style="font-size: 14px; margin-bottom: 40px;">
                            For each question, you will be given a probe (left) and candidate (right). For one-to-one
                            (single candidate), click one of the buttons (Match, Non-Match, Inconclusive) to answer the
                            question. For one-to-many (multiple candidates), drag and drop the candidates into the boxes
                            (Match, Non-Match, Inconclusive) according to your answers (you can also toggle one-to-one view
                            to answer a one-to-many question like a one-to-one question).
                        </p>
                        <button type="button" class="btn btn-success" style="font-size: large;" @click="emitCycle"
                            :disabled="preventButtonSpam">
                            Start Test
                        </button>
                        <p style="margin-top: 2em; font-size: 14px;">
                            When ready, press the 'Start Test' button above.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    </Transition>
</template>
<script>
import { Calendar } from 'v-calendar';

export default {
    name: 'TestStart',
    emits: ['startTest'],
    components: {
        Calendar
    },
    props: {
        test_name: {
            type: String
        },
        test_info: {
            type: String
        },
        test_due: {
            type: String
        },
        test_prog: {
            type: Number
        }
    },
    data() {
        return {
            preventButtonSpam: false
        }
    },
    methods: {
        emitCycle() {
            this.$emit('startTest')
            this.preventButtonSpam = true // Should not be disabled forever
        }
    }
}
</script>
<style scoped>
.flex-container {
    display: inline-block;
    margin-top: 30px;
}

.start-container {
    background-color: #fff;
    margin-top: 1em;
    padding: 25px 40px;
    margin: auto;
    width: 50%;
    border: 3px solid rgb(203, 203, 203);
    border-radius: 10px;
}
</style>