<template>
    <div class="page">
        <div class="flex-container">
            <div class="sidebar" style="overflow-x: hidden;">
                <SidebarMenu
                    :menu="menu"
                    :relative="true"
                    @item-click="onItemClick"
                    style="min-height: 92vh;"
                />
            </div>

            <div class="container" style="min-height: 92vh;" v-if="testList.givenResults.length > 0">
                <div class="row justify-content-center">
                    <div v-if="current_question === 0" class="col" style="text-align: center;">
                        <h1>{{testList.completed[givenTestIndex]['test']['name']}}</h1>
                        <h4>Overall Test Results</h4>
                        <div class="card bg-light">
                            <h5>
                                Overall Score
                            </h5>
                            <p>
                                {{ (testList.givenResults[current_question]['score']*100).toFixed(2) }}%
                            </p>
                            <hr :style="{marginTop: '1em'}" />

                            <div :class="[late_flag ? 'late' : 'on-time' , 'completion-box']">
                                <p><b>Completed {{ late_flag?'late':'on-time!' }}</b></p>
                                <p style="margin-top: 1em;"><i>Successfully answered all questions <u>{{ late_flag?'after':'before' }}</u> the due date.</i></p>
                                <p style="margin-top: 1em;"><b>Due:</b> {{ new Date(testList.completed[givenTestIndex].due).toLocaleDateString() }}</p>
                                <p><b>Started:</b> {{ new Date(testList.completed[givenTestIndex].started).toLocaleDateString() }}</p>
                                <p><b>Completed:</b> {{ new Date(testList.completed[givenTestIndex].finished).toLocaleDateString() }}</p>
                            </div>

                            <div v-if="testList.completed[givenTestIndex].retry">
                                <button 
                                    type="button" 
                                    class="btn btn-success large"
                                    @click="createRetry()"
                                >
                                    Retry Test
                                </button>
                            </div>
                        </div>

                        <div class="card bg-light">
                            <h5>Statistics</h5>
                            <p>
                                Total Number of Questions: {{ testList.completed[givenTestIndex]['progress'].length }}
                            </p>
                            <hr />
                            <p>
                                Number of Correct Matches: {{ testList.givenResults[current_question]['tp_count'] }}
                            </p>
                            <p>
                                Number of Correct Non-Matches: {{ testList.givenResults[current_question]['tn_count'] }}
                            </p>
                            <p>
                                Number of Incorrect Matches: {{ testList.givenResults[current_question]['fp_count'] }} ({{ testList.givenResults[current_question]['ic_fp_count'] }} Inconclusives)
                            </p>
                            <p>
                                Number of Incorrect Non-Matches: {{ testList.givenResults[current_question]['fn_count'] }} ({{ testList.givenResults[current_question]['ic_fn_count'] }} Inconclusives)
                            </p>
                        </div>

                        <div class="card bg-light" style="height: auto;">
                            <h5>Answer Distribution</h5>
                            <GenericChart
                                :plotType="'bar'"
                                :data="{
                                    labels: [
                                        'Correct Matches',
                                        'Correct Non-Matches',
                                        'Incorrect Matches',
                                        'Incorrect Non-Matches'
                                    ],
                                    datasets: [
                                        {
                                            label: 'Inconclusives',
                                            data: [
                                                0,
                                                0,
                                                testList.givenResults[current_question]['ic_fp_count'],
                                                testList.givenResults[current_question]['ic_fn_count']
                                            ],
                                            backgroundColor: [
                                                'orange',
                                                'orange',
                                                'orange',
                                                'orange'
                                            ],
                                            hoverOffset: 4
                                        },
                                        {
                                            label: 'Total',
                                            data: [
                                                testList.givenResults[current_question]['tp_count'],
                                                testList.givenResults[current_question]['tn_count'],
                                                testList.givenResults[current_question]['fp_count'],
                                                testList.givenResults[current_question]['fn_count'],
                                            ],
                                            backgroundColor: [
                                                '#06D6A0',
                                                '#49b0e3',
                                                '#EF476F',
                                                '#EF476F'
                                            ],
                                            hoverOffset: 4
                                        },
                                    ]
                                }"
                                :options="{
                                    grouped: false,
                                    animation: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        }
                                    },
                                    scales: {
                                        x: {
                                            ticks: {
                                                font: {
                                                    size: 10,
                                                }
                                            }
                                        }
                                    },
                                }"
                                :dynamicID="'canvas'"
                            />
                        </div>
                    </div>
                    <div v-if="current_question !== 0" class="col" style="text-align: center;">
                        <QuestionResults :test_name="testList.completed[givenTestIndex]['test']['name']" 
                            :prop_question="current_question" :given_results="testList.givenResults" 
                            :given_completed="testList.completed[givenTestIndex]['progress']"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
    import { testListStore } from '../stores/store'
</script>

<script>
    import GenericChart from '../components/GenericChart.vue'
    import { SidebarMenu } from 'vue-sidebar-menu'
    import QuestionResults from "../components/result/QuestionResults.vue";
    import 'chart.js/auto';
    import axios from 'axios';

    export default {
        components: {
            GenericChart,
            SidebarMenu,
            QuestionResults
        },
        data() {
            return {
                testList: testListStore(),
                givenTestIndex: null,
                overallScore: null,
                testResult: {},
                menu: {},
                current_question: 0,
                attrs: [],
                late_flag: false
            }
        },
        methods: {
            inited(viewer) {
                this.$viewer = viewer
            },
            secondinited(viewer){
                this.$secondviewer = viewer
            },
            onItemClick(event, item){
                this.current_question = item.index
                // TODO - Disable currently selected menu button
                this.$forceUpdate()
            },
            createRetry() {
                axios(
                    {
                        method: 'post',
                        url: `${process.env.VUE_APP_API}/session/retry/${this.testList.completed[this.givenTestIndex]['_id']}`,
                        withCredentials: true
                    }
                )
                    .then(()=> {
                        this.testList.$patch({
                            selectedTest: null,
                            popUpInfo: {
                                type: 'success',
                                message: `Successfully created a new test attempt for you to retry '${this.testList.completed[this.givenTestIndex]['test']['name']}'.`,
                                header: `Created New Attempt`,
                                style: { 
                                    iconSize: 35, 
                                    iconType: 'solid', 
                                    position: 'bottom right' 
                                } 
                            }
                        });
                        this.$router.replace({path:'/dashboard'});
                    })
            },
            filterNonMatch() {
                return this.testList.givenResults[this.current_question]['fn'].filter(candidate => !this.testList.completed[this.givenTestIndex]['progress'][this.current_question-1]['inc'].includes(candidate) )
            },
            filterMatch() {
                return this.testList.givenResults[this.current_question]['fp'].filter(candidate => !this.testList.completed[this.givenTestIndex]['progress'][this.current_question-1]['inc'].includes(candidate) )
            }
        },
        computed: {
            baseImageURL() {
                return `${process.env.VUE_APP_API}/session/images/`
            }
        },
        created() {
            this.testResult = {}
            this.menu = [
                {
                    header: 'Results Navigation',
                    hiddenOnCollapse: true
                },
                {
                    title: 'Overall',
                    index: 0,
                    icon: 'bi-house-fill'
                }
            ]

            this.givenTestIndex = this.testList.completed.findIndex((test) => test['_id'] === this.testList.selectedTest)
            this.late_flag = new Date(this.testList.completed[this.givenTestIndex].finished) > new Date(this.testList.completed[this.givenTestIndex].due)
            axios.get(`${process.env.VUE_APP_API}/session/results/${this.testList.selectedTest}`,{withCredentials:true})
                .then(results => {
                    // Save results data
                    let testResult = results.data

                    // Generate overall score
                    let total_tp = 0
                    let total_tn = 0
                    let total_fp = 0
                    let total_fn = 0
                    let total_ic_fp = 0
                    let total_ic_fn = 0
                    let total_score = 0
                    let all_results = []
                    let len = Object.keys(testResult).length
                    
                    for (var i = 0; i < len; i++) {
                        let result = testResult[i.toString()]
                        all_results.push(result)
                        
                        total_tp += result["tp_count"]
                        total_tn += result["tn_count"]
                        total_fp += result["fp_count"]
                        total_fn += result["fn_count"]

                        if ("ic_fp_count" in result) {
                            total_ic_fp += result["ic_fp_count"]
                        }

                        if ("ic_fn_count" in result) {
                            total_ic_fn += result["ic_fn_count"]
                        }

                        total_score += result["score"]
                    }

                    const correct_qid_order = all_results.map(result => result.question) // Get new question order based on result's order
                    const reordered_progress = Array.apply(null, Array(correct_qid_order.length)).map(function () { }) 
                    correct_qid_order.forEach((qid, index) => { // Reorder the data within state to match the order it is returned from result.
                        let new_index = this.testList.completed[this.givenTestIndex]['progress'].findIndex(question => question.question == qid)
                        reordered_progress[index] = this.testList.completed[this.givenTestIndex]['progress'][new_index]
                    })
                    this.testList.completed[this.givenTestIndex]['progress'] = reordered_progress
                    
                    all_results.unshift({
                        'tp_count': total_tp,
                        'tn_count': total_tn,
                        'fp_count': total_fp,
                        'fn_count': total_fn,
                        'ic_fp_count': total_ic_fp,
                        'ic_fn_count': total_ic_fn,
                        'score': total_score/len
                    })

                    this.testList["givenResults"] = all_results

                    for (const key in this.testList.completed[this.givenTestIndex]['progress']) {
                        var current_index = (parseInt(key, 10)+1)

                        let score = this.testList["givenResults"][current_index]['score']
                        let icon = 'bi-check2'

                        if (score < 1) {
                            icon = "bi-x-lg"
                        }
                        
                        this.menu.push(
                        {
                            title: `Question ${current_index}`,
                            index: parseInt(key, 10)+1,
                            icon: icon,
                            // disabled: (current_index===1)
                        }
                        );
                    }
                })
        }
    };
</script>

<style scoped>
.flex-container {
    display: flex;
}

.completion-box {
    border: none;
    border-radius: 1em;
    padding: 0.75em;
    margin-bottom: 1em;
}

.on-time {
    background-color: rgba(2, 245, 67, 0.1);
}

.late {
    background-color: rgba(220, 38, 38, 0.1);
}

.inspect{
    margin-top: 0;
    height: 500px;
    margin: 1em 1em 1em 1em;
}
.answer-box {
    padding: 20px;
    overflow: auto;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 2em;
    width: 50%;
}

p {
    margin: 0 0 2px 0;
}

h5 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 5px;
}

.card {
    border: none;
    padding: 30px;
    width: 50%;
    margin: 30px auto;
}

.sticky {
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 0;
    /* background-color: beige; */
    padding: 2em;
    width: 50%;
    margin: auto;
    z-index: 10;
}
</style>