<template>
    <div>
        <h1>{{ test_name }}</h1>
                        
        <div class="sticky bg-light">
            <h4 >Question {{ determineQuestion() }}</h4>
            <div v-if="pagination">
                <v-pagination
                    class="page-align"
                    v-model="current_question"
                    :pages="given_completed.length"
                />
                <div class="row justify-content-evenly score-align">
                    <div class="col">
                        <h5>Score</h5>
                        <p>{{ (given_results[determineQuestion()]['score']*100).toFixed(2) }}%</p>
                    </div>
                    <div class="col">
                        <h5>Completion Time</h5>
                        <p>{{ given_results[determineQuestion()]['elapse'] }} seconds</p>
                    </div>
                </div>
            </div>
        </div>
        

        <div v-if="!pagination" class="card bg-light">
            <h5>Score</h5>
            <p>
                {{ (given_results[determineQuestion()]['score']*100).toFixed(2) }}%
            </p>
        </div>

        <div 
            v-if="given_results[determineQuestion()]['probe'].length > 0"
            class="col answer-box"
            style="background-color: rgba(255, 153, 0, 0.15)" 
        >
            <h5>Probe</h5>    
            <DraggableImages :images="[given_results[determineQuestion()]['probe']]" :tiles="1" :img_width="'50%'" :url="baseImageURL" />
        </div>

        <div v-if="given_results[determineQuestion()]['tp_count'] + given_results[determineQuestion()]['tn_count'] + given_results[determineQuestion()]['fp_count'] + given_results[determineQuestion()]['fn_count'] == 1">
            <div 
                class="col answer-box"
                style="background-color: rgba(255, 153, 0, 0.15)" 
            >
                <h5>Correct Answer</h5>    
                <p>{{ given_results[determineQuestion()]['tp_count'] + given_results[determineQuestion()]['fn_count'] < 1 ? 'Non-Match':'Match' }}</p>
            </div>
            
            <div style="width: 50%; margin: 30px auto;">
                <h5 style="font-size: 25px;">{{ user_name }} Answer</h5>
                <hr/>
            </div>

            <div 
                class="col answer-box" 
                style="background-color: rgba(6, 214, 75, 0.2)"
                v-if="given_results[determineQuestion()]['tp_count'] > 0"
            >
                <h5>Correctly Matched</h5>    
                <DraggableImages :images="given_results[determineQuestion()]['tp']" :tiles="1" :img_width="'50%'" :url="baseImageURL" />
            </div>
            
            <div 
                class="col answer-box" 
                style="background-color: rgba(48, 118, 230, 0.2)"
                v-if="given_results[determineQuestion()]['tn_count'] > 0"
            >
                <h5>Correctly Non-Matched</h5>
                <DraggableImages :images="given_results[determineQuestion()]['tn']" :tiles="1" :img_width="'50%'" :url="baseImageURL" />
            </div>
            
            <div 
                class="col answer-box" 
                style="background-color: rgba(214, 6, 6, 0.2)"
                v-if="filterMatch().length > 0"
            >
                <h5>Incorrectly Matched</h5>        
                <DraggableImages :images="filterMatch()" :tiles="1" :img_width="'50%'" :url="baseImageURL" />
            </div>
            
            <div
                class="col answer-box" 
                style="background-color: rgba(214, 6, 6, 0.2)"
                v-if="filterNonMatch().length > 0"
            >
                <h5>Incorrectly Non-Matched</h5>
                <DraggableImages :images="filterNonMatch()" :tiles="1" :img_width="'50%'" :url="baseImageURL" />
            </div>
            <div
                class="col answer-box" 
                style="background-color: rgba(235, 220, 10, 0.3)"
                v-if="given_completed[determineQuestion()-1]['inc'].length > 0"
            >
                <h5>Inconclusive</h5>
                <DraggableImages :images="given_completed[determineQuestion()-1]['inc']" 
                    :tiles="1" :img_width="'50%'" :url="baseImageURL" />
            </div>
        </div>
        <div v-else>
            <div 
                v-if="given_results[determineQuestion()]['tp_count'] + given_results[determineQuestion()]['fn_count'] > 0"
                class="col answer-box"
                style="background-color: rgba(255, 153, 0, 0.15)" 
            >
                <h5>Correct Answers</h5>    
                <DraggableImages :images="given_results[determineQuestion()]['tp'].concat(given_results[determineQuestion()]['fn'])" :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
            <div 
                v-else
                class="col answer-box" 
                style="background-color: rgba(255, 153, 0, 0.15)"
            >
                <h5>Correct Answers</h5>
                <p>No mated candidates for this question.</p>
            </div>

            <div style="width: 50%; margin: 30px auto;">
                <h5 style="font-size: 25px;">{{ user_name }} Results</h5>
                <hr/>
            </div>

            <div 
                class="col answer-box" 
                style="background-color: rgba(6, 214, 75, 0.2)"
                v-if="given_results[determineQuestion()]['tp_count'] > 0"
            >
                <h5>Correctly Matched</h5>    
                <DraggableImages :images="given_results[determineQuestion()]['tp']" :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
            
            <div 
                class="col answer-box" 
                style="background-color: rgba(48, 118, 230, 0.2)"
                v-if="given_results[determineQuestion()]['tn_count'] > 0"
            >
                <h5>Correctly Non-Matched</h5>
                <DraggableImages :images="given_results[determineQuestion()]['tn']" :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
            
            <div 
                class="col answer-box" 
                style="background-color: rgba(214, 6, 6, 0.2)"
                v-if="filterMatch().length > 0"
            >
                <h5>Incorrectly Matched</h5>        
                <DraggableImages :images="filterMatch()" :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
            
            <div
                class="col answer-box" 
                style="background-color: rgba(214, 6, 6, 0.2)"
                v-if="filterNonMatch().length > 0"
            >
                <h5>Incorrectly Non-Matched</h5>
                <DraggableImages :images="filterNonMatch()" 
                    :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
            <div
                class="col answer-box" 
                style="background-color: rgba(235, 220, 10, 0.3)"
                v-if="given_completed[determineQuestion()-1]['inc'].length > 0"
            >
                <h5>Inconclusive</h5>
                <DraggableImages :images="given_completed[determineQuestion()-1]['inc']" 
                    :tiles="5" :img_width="'75px'" :url="baseImageURL" />
            </div>
        </div>
    </div>
</template>
<script>
    import DraggableImages from "../../components/DraggableImages.vue";
    import VPagination from "@hennge/vue3-pagination";
    import "@hennge/vue3-pagination/dist/vue3-pagination.css";

    export default {
        name: 'QuestionResults',
        components: {
            DraggableImages,
            VPagination
        },
        props: {
            pagination: {
                type: Boolean,
                default: false
            },
            test_name: {
                type: String
            },
            user_name: {
                type: String,
                default: "Your"
            },
            prop_question: {
                type: Number
            },
            given_results:{
                type: [],
                default() {
                    return [];
                }
            },
            given_completed:{
                type: [],
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                current_question: 1
            }
        },
        methods: {
            determineQuestion() {
                return this.pagination?this.current_question:this.prop_question
            },
            filterNonMatch() {
                return this.given_results[this.determineQuestion()]['fn'].filter(candidate => !this.given_completed[this.determineQuestion()-1]['inc'].includes(candidate) )
            },
            filterMatch() {
                return this.given_results[this.determineQuestion()]['fp'].filter(candidate => !this.given_completed[this.determineQuestion()-1]['inc'].includes(candidate) )
            }
        },
        computed: {
            baseImageURL() {
                return `${process.env.VUE_APP_API}/session/images/`
            }
        },
    }

</script>

<style lang="scss" scoped>

    .answer-box {
        padding: 20px;
        overflow: auto;
        margin: 0 auto;
        border-radius: 5px;
        margin-bottom: 2em;
        width: 50%;
    }

    .card {
        border: none;
        padding: 30px;
        width: 50%;
        margin: 0 auto 2em auto;
    }

    .sticky {
        position: -webkit-sticky; /* Safari & IE */
        position: sticky;
        top: 0;
        /* background-color: beige; */
        padding: 2em;
        width: 50%;
        margin: 0 auto 2em auto;
        z-index: 10;

        .page-align {
            margin: 1em 0 0 30%
        }

        .score-align {
            margin-top: 0.5em;
        }
    }

    p {
        margin: 0 0 2px 0;
    }

    h5 {
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 5px;
    }

    .Pagination {
        display: flex;
    }

</style>