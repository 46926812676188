<template>
    <div>
        <vue-basic-alert :duration="300" :closeIn="7000" ref="alert" />

        <Transition name="high" appear>
            <div class="container container-override" v-if="sortedTests.length != 0">
                <h1>Your Tests</h1>

                <div class="row justify-content-center">
                    <div class="col-3 card bg-light" v-for="test in sortedTests" v-bind:key="test['_id']" data-test="test">
                        <TestCard v-if="test.original == 'cont'" :test_status="test.status" :test_name="test.test.name"
                            :test_due="test.due.toString().split('T')[0]" :test_progress="test.progress" :test_id="test.test.id"
                            @redirectToTest="setAndRedirect('/test', test['_id'])" />
                        <TestCard v-else-if="test.original == 'new'" :test_name="test.name" :test_new="true"
                            :test_due="test.due.toString().split('T')[0]" :test_status="-1" :test_id="test.test.id"
                            @redirectToTest="setAndRedirect('/test', test['test'], true)" />
                    </div>
                </div>

                <p style="font-size: 14px; color: hsl(0, 0%, 35%)">Your most urgent tests are displayed here, view all
                    tests
                    below</p>

                <router-link to="/list?tab=ongoing">
                    <button type="button" class="btn btn-danger">
                        View All
                    </button>
                </router-link>
            </div>
        </Transition>
        <Transition name="medium" appear>
            <div class="container" v-if="testList.completed.length != 0">
                <h1>Completed Tests</h1>

                <div class="row justify-content-center">
                    <div class="col-3 card bg-light" v-for="test in shortenCompleted(testList.completed)"
                        v-bind:key="test.id" data-test="test">
                        <TestCard :test_status="test.status" :test_name="test.test.name" :test_progress="test.progress"
                            :test_due="test.finished.split('T')[0]" @redirectToResults="setAndRedirect('/results', test['_id'])" />
                    </div>
                </div>

                <p style="font-size: 14px; color: hsl(0, 0%, 35%)">Your completed tests are ordered by most recent completion.<br /> View all
                    completed tests by using the button below</p>

                <router-link to="/list?tab=completed">
                    <button type="button" class="btn btn-danger">
                        View Completed Tests
                    </button>
                </router-link>

            </div>
        </Transition>
        <div class="container"
            v-if="testList.tests.length === 0 && testList.completed.length === 0 && testList.newTests.length === 0">
            <Transition name="high" appear>
                <h1>
                    Welcome to BxASSURE
                </h1>
            </Transition>
            <Transition name="medium" appear>
                <div style="width: 50%; margin: 0 auto">
                    <p style="color: hsl(0, 0%, 35%)">
                        You currently do not have any tests assigned to yourself. Please contact your admin to provide you
                        with test material
                    </p>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import { testListStore } from '../stores/store'
import TestCard from '../components/TestCard.vue'
import axios from 'axios'

export default {
    name: 'AdminDashboard',
    components: {
        TestCard
    },
    data() {
        return {
            testList: testListStore(),
            sortedTests: []
        }
    },
    methods: {
        shortenCompleted(given_list) {
            if (given_list.length > 3) {
                const localReference = given_list
                return localReference.slice(0, 3);
            }
            return given_list
        },
        setAndRedirect(path, test_id, test_new = false) {
            this.testList.$patch({
                selectedTest: test_id
            });

            if (test_new) {
                this.testList.$patch({
                    selectedTestNew: test_new
                });
            }
            this.$router.replace({ path: path });
        },
        urgencyFilter(newTests, continueTests) {
            const newMappedTests = newTests.map(obj => ({ ...obj, original: 'new' }))
            const continueMappedTests = continueTests.map(obj => ({ ...obj, original: 'cont' }))
            const combined = newMappedTests.concat(continueMappedTests)
            // combined.sort((a, b) => new Date(combined[a].due) - new Date(combined[b].due))
            combined.sort((a, b) => a.due.localeCompare(b.due))
            return combined.slice(0, 3)
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_API}/session/info`, { withCredentials: true })
            .then(result => {
                // result.data.assigned => not currently used
                this.testList.newTests = result.data.new
                const promise_array = []
                for (let progressTest of result.data.progress) {
                    const promise = axios.get(`${process.env.VUE_APP_API}/session/tests/${progressTest}`, { withCredentials: true })
                        .then(results => {
                            if (results.data.status === 2) {
                                if (this.testList.completed.every((current_test) => current_test['_id'] !== progressTest)) {
                                    this.testList.completed.push(results.data)
                                    const test_index = this.testList.tests.findIndex(element => element['_id'] === progressTest)
                                    if (test_index > -1) {
                                        this.testList.tests.splice(test_index, 1)
                                    }
                                }
                            } else {
                                if (this.testList.tests.every((current_test) => current_test['_id'] !== progressTest)) {
                                    this.testList.tests.push(results.data)
                                }
                            }

                        })
                    promise_array.push(promise)
                }

                Promise.all(promise_array)
                    .then(() => {
                        this.testList.completed.sort((itemA, itemB) => new Date(itemB.finished) - new Date(itemA.finished))
                        this.sortedTests = this.urgencyFilter(this.testList.newTests, this.testList.tests)
                    })
            })
        // TODO -> Readd pop-up once the state correctly updates.
        // if (Object.keys(this.testList.popUpInfo).length > 0) {
        //     this.$refs.alert.showAlert(
        //         this.testList.popUpInfo.type,
        //         this.testList.popUpInfo.message,
        //         this.testList.popUpInfo.header,
        //         this.testList.popUpInfo.style,
        //     )
        //     this.testList.$patch({
        //         popUpInfo: {}
        //     });
        // }
    }
}
</script>

<style scoped lang="scss">
h1 {
    padding: 30px 0;
    font-size: 30px;
}

.btn {
    margin: 10px 0 40px 0;
}

.card {
    height: 500px;
    margin: 0 20px 30px 20px;
    padding: 50px;
    width: 300px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-pages {
    margin: 0 20px 30px 20px;
    padding: 50px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    i {
        font-size: 50px;
        margin-bottom: 40px;
    }
}
</style>