<template>
    <!-- Alert -->
    <vue-basic-alert :duration="300" :closeIn="7000" ref="alert" />
    <!-- Admin -->
    <div v-if="testList.permissions.includes('super:write')" class="page">
        <div class="tabs">
            <!-- View Tests -->
            <input class="input" :style="currentVars2" name="tabs" type="radio" id="tab-1" :checked="view_tests" />
            <label class="label" :style="currentVars2" for="tab-1">
                View Tests
            </label>
            <div v-if="waitLoad" class="panel">
                <OwnedTests :managed_tests="testList.managedTests" @redirect="(test_id) => redirectProgress(test_id)"/>
            </div>

            <!-- Delete Tests -->
            <input class="input" :style="currentVars2" name="tabs" type="radio" id="tab-2" />
            <label class="label" :style="currentVars2" for="tab-2">
                Delete Tests
            </label>
            <div class="panel">
                <h1>Select Tests to Delete</h1>

                <div class="multiselect-container" style="margin: 30px auto;">
                    <VueMultiselect v-model="selected_tests" :options="testList.managedTests" :multiple="true"
                        :close-on-select="true" label="name" track-by="_id" />
                </div>
                <div style="margin: 2em 18em;">
                    <input v-model="confirm_delete" type="checkbox">
                    <label class="form-check-label" for="flexCheckDefault">
                        <b @click="confirm_delete=!confirm_delete" style="cursor:pointer">
                            I understand that pressing 'Remove Tests' will remove the chosen tests above and all associated in-progress attempts and results.
                        </b>
                    </label>
                </div>
                
                <button class="btn btn-danger" :disabled="selected_tests.length < 1 || !confirm_delete" @click="deleteTests()">Remove
                    Tests</button>
            </div>
        </div>
    </div>
    <!-- Coordinator -->
    <div v-else-if="testList.permissions.includes('admin:write')" class="page">
        <div v-if="waitLoad">
            <OwnedTests :managed_tests="testList.managedTests" @redirect="(test_id) => redirectProgress(test_id)"/>
        </div>
    </div>
    <!-- User -->
    <div v-else class="page">
        <div class="tabs">
            <input class="input" :style="currentVars" name="tabs" type="radio" id="tab-1" :checked="onGoingCheck" />
            <label class="label" :style="currentVars" for="tab-1"
                title="Click on an 'on-going' test to undertake the given test.">
                Current
            </label>
            <div class="panel">
                <div v-if="allTests.length === 0">
                    <p style="margin-top: 20px;">
                        You have no on-going tests.
                    </p>
                </div>
                <Transition name="high" appear>
                    <div>
                        <div v-for="[index, test] in allTests.entries()" :key="`${index} test`">
                            <div v-if="test.original == 'cont'">
                                <TestList :test_id="test['_id']" :test_info="test.test.info" :test_status="test.status"
                                    :test_name="test.test.name" :test_due="test.due" :test_progress="test.progress"
                                    :index="index" :real_test_id="test.test.id" />
                            </div>
                            <div v-if="test.original == 'new'">
                                <TestList :test_id="test.test" :test_info="test.info" :test_name="test.name"
                                    :test_new="true" :test_due="test.due" :test_status="-1" :index="index" />
                            </div>

                        </div>
                    </div>
                </Transition>
            </div>
            <input class="input" :style="completedVars" name="tabs" type="radio" id="tab-3" :checked="completedCheck" />
            <label class="label" :style="completedVars" for="tab-3"
                title="Click on a 'completed' test to show the results of a given test.">
                Completed
            </label>
            <div class="panel">
                <div v-if="testList.completed.length === 0">
                    <p style="margin-top: 20px;">
                        You have no completed tests.
                    </p>
                </div>
                <Transition name="high" appear>
                    <div>
                        <div v-for="test in testList.completed" v-bind:key="test.id">
                            <TestList :test_id="test['_id']" :test_status="test.status" :test_name="test.test.name"
                                :test_info="test.test.info" :test_progress="test.progress" :test_due="test.finished"
                                :disable-buttons="true" />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store'
let testList = testListStore()
</script>

<script>
import TestList from '../components/TestList.vue'
import OwnedTests from '../components/OwnedTests.vue'
import { isUndefined } from 'lodash'
import VueMultiselect from 'vue-multiselect'
import axios from 'axios'

export default {
    name: 'ListPage',
    components: {
        VueMultiselect,
        TestList,
        OwnedTests
    },
    data() {
        return {
            onGoingCheck: (this.$route.query.tab === "ongoing") ? "checked" : null,
            completedCheck: (this.$route.query.tab === "completed") ? "checked" : null,
            testList: testListStore(),
            allTests: [],
            selected_tests: [],
            view_tests: true,
            confirm_delete: false,
            waitLoad: false
        }
    },
    methods: {
        redirectProgress(test_id) {
            this.testList.$patch({
                selectedTest: test_id
            });
            this.$router.replace({ path: '/progress' });
        },
        deleteTests() {
            let promises = []

            for (let i = 0; i < this.selected_tests.length; i++) {
                let test_id = this.selected_tests[i]["_id"]
                let promise = axios.delete(
                    `${process.env.VUE_APP_API}/manage/tests/${test_id}/purge`,
                    {
                        withCredentials: true
                    }
                )
                promises.push(promise)
            }

            Promise.all(promises).then(() => {
                let delete_tests = []
                for (let i = 0; i < this.selected_tests.length; i++) {
                    let test_id_remove = this.selected_tests[i]["_id"]
                    for (let j = 0; j < this.testList.managedTests.length; j++) {
                        let test = this.testList.managedTests[j]
                        let test_id = test["_id"]
                        if (test_id == test_id_remove) {
                            delete_tests.push(test)
                        }
                    }
                }
                this.testList.managedTests = this.testList.managedTests.filter(
                    (element) => !delete_tests.includes(element)
                )
                this.selected_tests = []
                this.confirm_delete = false
                this.$refs.alert.showAlert(
                    'success',
                    'Removed all associated test data from BxASSURE.',
                    'Successfully Deleted',
                    {
                        iconSize: 35,
                        iconType: 'solid',
                        position: 'bottom right'
                    }
                )
            })
        }
    },
    mounted() {
        if (!this.testList.permissions.includes('admin:write')) {
            const newMappedTests = this.testList.newTests.map(obj => ({ ...obj, original: 'new' }))
            const continueMappedTests = this.testList.tests.map(obj => ({ ...obj, original: 'cont' }))
            this.allTests = newMappedTests.concat(continueMappedTests)
            if (isUndefined(this.$route.query.tab)) {
                this.onGoingCheck = true
            }
        } else {
            axios.get(`${process.env.VUE_APP_API}/manage/tests/?owner=${this.testList.username}`, { withCredentials: true })
                .then(results => {
                    const managedTests = results.data

                    const allAssignPromises = []
                    const testAssignedUsers = {}
                    managedTests.forEach(test => {
                        const promise = axios.get( `${process.env.VUE_APP_API}/manage/assigned/${test['_id']}`, { withCredentials: true } )
                            .then(response => { testAssignedUsers[test._id] = response.data })
                            .catch(() => { testAssignedUsers[test._id] = [] })
                        allAssignPromises.push(promise)
                    });

                    Promise.all(allAssignPromises)
                        .then(() => {
                            const allManagePromises = []
                            managedTests.forEach((test, i) => {
                                const promise = axios.get(
                                    `${process.env.VUE_APP_API}/manage/progress/?test_id=${test['_id']}`,
                                    {
                                        withCredentials: true
                                    }
                                )
                                    .then(results => {
                                        const localTestProgress = results.data
                                        let total_completed = [...new Set(localTestProgress
                                            .filter(progress => progress.status === 2)
                                            .map(progress => progress.owner))].length
                                        managedTests[i]['total-assigned'] = testAssignedUsers[test._id].length 
                                        managedTests[i]['total-completed'] = total_completed
                                    })
                                    .catch(() => {
                                        managedTests[i]['total-assigned'] = testAssignedUsers[test._id].length 
                                        managedTests[i]['total-completed'] = 0
                                    })
                                allManagePromises.push(promise)
                            })

                            Promise.all(allManagePromises)
                                .then(() => {
                                    this.testList.managedTests = managedTests
                                    this.waitLoad = true
                                })
                        })
                })
                .catch(() => { 
                    this.testList.managedTests = []
                    this.waitLoad = true 
                })
        }
    },
    computed: {
        currentVars() {
            return {
                '--bg-color': '#0d6efd',
                '--text-color': 'white'
            }
        },
        completedVars() {
            return {
                '--bg-color': '#198754',
                '--text-color': 'white'
            }
        },
        currentVars2() {
            return {
                '--bg-color': '#fff'
            }
        }
    }
};
</script>

<style scoped lang="scss">
h1 {
    padding: 30px 0;
    font-size: 30px;
}


</style>
