<template>
    <div class="container">
        <Transition name="medium" appear>
            <div style="padding-bottom: 20px;">
                <h1>Frequently Used</h1>
                <p>Frequently used administrator pages</p>
            </div>
        </Transition>

        <Transition name="custom"
            style="--opacity_ease_active: 3s; --transform_ease_active: 1.5s; --transform_leave_active:0px; --opacity_ease_to:0; --transform_leave_to:100px "
            appear>
            <div class="row justify-content-center" style="margin-bottom: 50px;">
                <div class="col-3 admin-pages bg-light" @click="redirectAdmin('/users')">
                    <h2>Users Management</h2>
                    <i class="bi-person-plus"></i>
                    <hr>
                    <button type="button" class="btn btn-secondary">Update</button>
                </div>
                <div v-if="testList.permissions.includes('super:write')" class="col-3 admin-pages bg-light"
                    @click="redirectAdmin('/createtest')">
                    <h2>Create Tests</h2>
                    <i class="bi-folder-plus"></i>
                    <hr>
                    <button type="button" class="btn btn-secondary">Create</button>
                </div>
                <div class="col-3 admin-pages bg-light" @click="redirectAdmin('/assigntest')">
                    <h2>Assign Tests</h2>
                    <i class="bi-person-lines-fill"></i>
                    <hr>
                    <button type="button" class="btn btn-secondary">Delegate</button>
                </div>
            </div>
        </Transition>

        <Transition name="custom"
            style="--opacity_ease_active: 4s; --transform_ease_active: 2s; --transform_leave_active:0px; --opacity_ease_to:0; --transform_leave_to:100px "
            appear>
            <div v-if="testList.managedTests.length !== 0" class="container">
                <h1>Owned Tests</h1>
                <div class="row justify-content-center ">
                    <div v-for="ownedtest in shortenCompleted(testList.managedTests)" v-bind:key="ownedtest['_id']"
                        class="col-3 card bg-light">
                        <AdminCard :test_name="ownedtest.name" :test_assigned="ownedtest['total-assigned']"
                            :test_completed="ownedtest['total-completed']" :test_info="`Created: ${new Date(ownedtest.modified).toLocaleDateString()}`"
                            @redirect-to-progress="setAndRedirect('/progress', ownedtest['_id'])" />

                    </div>
                    <p style="font-style: italic ; font-size: 14px; color: hsl(0, 0%, 35%)">Your most recently created tests are displayed here, 
                        view all tests below.</p>
                    <router-link to="/list">
                        <button type="button" class="btn btn-danger">
                            View All Tests
                        </button>
                    </router-link>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import { testListStore } from '../stores/store'
import AdminCard from '../components/AdminCard.vue'
import axios from 'axios'

export default {
    name: 'AdminDashboard',
    components: {
        AdminCard
    },
    data() {
        return {
            testList: testListStore()
        }
    },
    methods: {
        redirectAdmin(path) {
            this.$router.replace({ path: path });
        },
        shortenCompleted(given_list) {
            if (given_list.length > 3) {
                const localReference = given_list
                localReference.sort((a, b) => new Date(b.modified) - new Date(a.modified))
                return localReference.slice(0, 3);
            }
            return given_list
        },
        setAndRedirect(path, test_id, test_new = false) {
            this.testList.$patch({
                selectedTest: test_id
            });

            if (test_new) {
                this.testList.$patch({
                    selectedTestNew: test_new
                });
            }
            this.$router.replace({ path: path });
        }
    },
    mounted() {
        axios.get(`${process.env.VUE_APP_API}/manage/tests/?owner=${this.testList.username}`, { withCredentials: true })
            .then(results => {
                const managedTests = results.data

                const allAssignPromises = []
                const testAssignedUsers = {}
                managedTests.forEach(test => {
                    const promise = axios.get( `${process.env.VUE_APP_API}/manage/assigned/${test['_id']}`, { withCredentials: true } )
                        .then(response => { testAssignedUsers[test._id] = response.data })
                        .catch(() => { testAssignedUsers[test._id] = [] })
                    allAssignPromises.push(promise)
                });

                Promise.all(allAssignPromises)
                    .then(() => {
                        const allManagePromises = []
                        managedTests.forEach((test, i) => {
                            const promise = axios.get(
                                `${process.env.VUE_APP_API}/manage/progress/?test_id=${test['_id']}`,
                                {
                                    withCredentials: true
                                }
                            )
                                .then(results => {
                                    const localTestProgress = results.data
                                    let total_completed = [...new Set(localTestProgress
                                        .filter(progress => progress.status === 2)
                                        .map(progress => progress.owner))].length
                                    managedTests[i]['total-assigned'] = testAssignedUsers[test._id].length 
                                    managedTests[i]['total-completed'] = total_completed
                                })
                                .catch(() => {
                                    managedTests[i]['total-assigned'] = testAssignedUsers[test._id].length 
                                    managedTests[i]['total-completed'] = 0
                                })
                            allManagePromises.push(promise)
                        })

                        Promise.all(allManagePromises)
                            .then(() => {
                                this.testList.managedTests = managedTests
                            })
                    })
            })
            .catch(() => {
                // Nothing needs to be done, just prevent the error from showing
            })
    }
}
</script>

<style scoped lang="scss">
h1 {
    padding: 30px 0;
    font-size: 30px;
}

.btn {
    margin: 10px;
}

.card {
    height: 500px;
    margin: 0 20px 30px 20px;
    padding: 50px;
    width: 300px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
}

.admin-pages {
    margin: 0 20px 30px 20px;
    padding: 50px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    i {
        font-size: 50px;
        margin-bottom: 40px;
    }
}
</style>