<template>
    <div class="page">
        <vue-basic-alert 
            :duration="300"
            :closeIn="7000"
            ref="alert" 
        />

        <Transition name="high" appear>
            <h1 style="padding-top: 30px;">Create Test</h1>
        </Transition>

        <Transition name="medium" appear>
            <div>
                <div v-if="!images_uploaded">
                    <h2>Upload Images</h2>
                    <input class="form-control uploader" ref="file" type="file" accept="image/*" multiple @change="imageUpload($event)"/>
                    <button v-if="images.length > 0" class="btn btn-danger" style="margin-bottom: 40px;" @click="submitImages()">Upload</button>
                </div>
                <div v-else>
                    <h2>Test Name <span :class="[test_name==''?'patch':'', 'bi-exclamation-circle-fill']" :title="input_title"></span></h2>
                    <input v-model="test_name" type="text" class="form-control text-input" placeholder="Enter the test name here" />
                    
                    <h2>Description <span :title="input_title"></span></h2>
                    <textarea v-model="test_info" class="form-control text-input" placeholder="Enter the test description here"></textarea>
                    
                    <h2>Upload Metadata <span :class="[csv.length==0?'patch':'', 'bi-exclamation-circle-fill']" :title="input_title"></span></h2>
                    <input class="form-control uploader" type="file" accept=".csv" @change="csvUpload($event)"/>
        
                    <h2>Select Owners <span :class="[selected_admins.length==0?'patch':'', 'bi-exclamation-circle-fill']" :title="input_title"></span></h2>
                    <div class="multiselect-container owner-select">
                        <loading 
                            v-model:active="isLoading"
                            :can-cancel="false"
                            :is-full-page="false"
                            :loader="'dots'"
                            :color="'#ff0000'"
                            :height="200"
                            :width="200"
                            :z-index="2"
                        />
                        <VueMultiselect
                            v-model="selected_admins"
                            :options="admins"
                            :multiple="true"
                            :close-on-select="true"
                            label="username"
                            track-by="_id"
                        />
                    </div>
                    
                    <button 
                        class="btn btn-danger"
                        style="margin-bottom: 50px;"
                        @click="createTest()" 
                        :disabled="test_name==''||csv.length==0||selected_admins.length==0"
                    >
                        Create Test
                    </button>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
  import { testListStore } from '../stores/store'
</script>

<script>
    import axios from 'axios'
    import 'vue-json-pretty/lib/styles.css'
    import Papa from 'papaparse'
    import VueMultiselect from 'vue-multiselect'
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';

    export default {
        name: 'CreateTestPage',
        components: {
            VueMultiselect,
            Loading
        },
        data() {
            return {
                testList: testListStore(),
                images: [],
                image_ids: {},
                json: {},
                test: {},
                admins: [],
                selected_admins: [],
                isLoading: false,
                input_title: "mandatory field required for creating a test",
                images_uploaded: false,
                files_uploaded: false,
                csv: [],
                csv_parsed: false,
                test_name: "",
                test_info: ""
            }
        },
        methods: {
            submitImages() {
                for (let i = 0; i < this.images.length; i++) {
                    let image = this.images[i]
                    let formData = new FormData();
                    formData.append('file', image)
                    
                    axios.post(`${process.env.VUE_APP_API}/manage/images/single/?type=candidate&identity=`,
                        formData,
                        {
                            withCredentials: true,
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(response => {
                        this.image_ids[image.name] = response.data._id
                    })
                }

                this.images_uploaded = true
            },
            csvUpload(event) {
                let file = event.target.files[0]
                this.parseCSV(file)
            },
            parseCSV(file) {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function(results) {
                        for (let i = 0; i < results.data.length; i++) {
                            let row = results.data[i]
                            
                            if (row.truth.toLowerCase() == 'true') {
                                row.truth = true
                            } else {
                                row.truth = false
                            }
                            
                            results.data[i] = row
                        }
                        
                        this.csv = results
                        this.csv_parsed = true
                    }.bind(this)
                })
            },
            getAdmins() {
                this.isLoading = true
                axios.get(
                    `${process.env.VUE_APP_API}/manage/coordinators/`,
                    {
                        withCredentials: true
                    }
                )
                    .then(response => {
                        this.admins = response.data
                        const current_admin_index = this.admins.findIndex((admin) => admin.username == this.testList.username)
                        if (current_admin_index != -1) {
                            this.selected_admins.push(this.admins[current_admin_index])
                        }
                    })
                    .catch(() => {
                        this.$refs.alert.showAlert(
                            'error', 
                            'Got an error when attempting to retrieve list of admins', 
                            `Failed to get Admin List`, 
                            { 
                                iconSize: 35, 
                                iconType: 'solid',
                                position: 'bottom right' 
                            } 
                        )
                        
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            createTest() {
                let questions = []
                let question_ids = []
                let question_registry = []
                let unique_probes = []
                const promises = []

                for (let i = 0; i < this.csv.data.length; i++) {
                    let row = this.csv.data[i]
                    let probe = row.probe

                    if (unique_probes.indexOf(probe) < 0) {
                        unique_probes.push(probe)
                    }
                }

                for (let i = 0; i < unique_probes.length; i++) {
                    let probe = unique_probes[i]

                    questions.push({
                        'probe': probe,
                        'candidates': [],
                        'truth': []
                    })
                }

                for (let i = 0; i < this.csv.data.length; i++) {
                    let row = this.csv.data[i]
                    let candidate = row.candidate
                    let probe = row.probe
                    let truth = row.truth

                    for (let i = 0; i < questions.length; i++) {
                        let question = questions[i]

                        if (question.probe === probe) {
                            question.candidates.push(candidate)

                            if (truth) {
                                question.truth.push(candidate)
                            }
                        }

                        questions[i] = question
                    }
                }

                for (let i = 0; i < questions.length; i++) {
                    let question = questions[i]
                    
                    // Upload question
                    question.description = ""
                    question.owner = ""
                    question.probe = this.image_ids[question.probe]
                    
                    for (let j = 0; j < question.candidates.length; j++) {
                        question.candidates[j] = this.image_ids[question.candidates[j]]
                    }
                    
                    const promise = axios.post(`${process.env.VUE_APP_API}/manage/questions/`,
                        question,
                        {
                            withCredentials: true
                        }
                    ).then(response => {
                        let question_id = response.data._id
                        question_ids.push(question_id)
                        
                        question_registry.push({
                            "index": i,
                            question: question_id
                        })

                        // Upload truth data for question
                        let truth = question.truth
                        let candidates = question.candidates

                        for (let k = 0; k < truth.length; k++) {
                            truth[k] = this.image_ids[truth[k]]
                        }

                        let match_scores = []

                        for (let k = 0; k < candidates.length; k++) {
                            match_scores.push({
                                "image": candidates[k],
                                "score": 0.0
                            })
                        }
                        
                        axios.post(`${process.env.VUE_APP_API}/manage/answers/`,
                            {
                                "question": question_id,
                                "truth": truth,
                                "candidates": match_scores
                            },
                            {
                                withCredentials: true
                            }
                        )
                    })

                    promises.push(promise)
                }

                if (this.test_name == null || this.test_name.trim() === '') {
                    this.test_name = "Test"
                }

                if (this.test_info == null || this.test_info.trim() === '') {
                    this.test_info = "No description."
                }

                Promise.all(promises).then(() => {
                    // Create test
                    axios.post(`${process.env.VUE_APP_API}/manage/tests/`,
                        {
                            "name": this.test_name,
                            "info": this.test_info,
                            "owner": this.selected_admins.map(admin => admin.username),
                            "category": "Face",
                            "questions": question_ids,
                            "order": question_registry
                        },
                        {
                            withCredentials: true
                        }
                    )
                        .then(() => {
                            this.$refs.alert.showAlert(
                                'success', 
                                `Successfully created '${this.test_name}' for the following admins: ${this.selected_admins.map(admin => admin.username).join(', ')}`, 
                                `Test Created`, 
                                { 
                                    iconSize: 35, 
                                    iconType: 'solid', 
                                    position: 'bottom right' 
                                } 
                            )
                        })
                        .catch(() => {
                            this.$refs.alert.showAlert(
                                'error', 
                                `Test creation failed when attempting to create '${this.test_name}'`, 
                                `Error! Test Creation`, 
                                { 
                                    iconSize: 35, 
                                    iconType: 'solid',
                                    position: 'bottom right' 
                                } 
                            )
                        })
                })
            },
            
            imageUpload(event) {
                this.images = event.target.files
            },
            
            jsonUpload(event) {
                let file = event.target.files[0]
                const fr = new FileReader()
                
                fr.onload = e => {
                    this.json = JSON.parse(e.target.result)
                }

                fr.readAsText(file)
            }
        },
        created() {
            this.getAdmins()
        }
    };
</script>

<style scoped>
    h2 {
        margin: 60px 0 30px 0;
    }

    .btn {
        margin-top: 20px;
    }

    .owner-select {
        margin: 20px auto;
    }

    .patch {
        color: rgb(243, 53, 53)
    }

    .uploader {
        width: 300px;
        margin: 20px auto;
        font-size: 14px;
    }

    .text-input {
        width: 50%;
        border: none;
        margin: 0 auto;
        color: hsl(0, 0%, 30%);
        padding: 10px 15px;
    }
    .text-input:focus {
        color: hsl(0, 0%, 30%);
    }

    .df-container {
        overflow: auto;
        max-height: 500px;
        max-width: 50%;
        margin: 40px auto;
        text-align: left;
        font-size: 14px;
        color: hsl(0, 0%, 30%);
    }

    .df-container input {
        border: none;
        color: hsl(0, 0%, 35%);
        background-color: inherit;
        font-size: 14px;
        font-weight: bold;
    }
</style>
