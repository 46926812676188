<template>
    <div class="custom-sidebar">
        <div class="sticky">
            <p style="margin-bottom:0.5em; color: white">{{ header }}</p>
            
            <div 
                class="sidebar-item"
                @click="$emit('navigation', home.index)"
                :style="{
                    backgroundColor: home.index === current?'#7d7d86':'',
                }"
            >
                <span :class="home.icon"></span> {{ this.home.title }}
            </div>

            <input type="text" v-model="searchVal" placeholder="Search users" />
            
            <div
                v-for="element in filteredList()"
                :key="element.title"
                class="sidebar-item"
                @click="$emit('navigation', element.index)"
                :style="{
                    backgroundColor: element.index === current?'#7d7d86':'',
                }"
            >
                <span :class="element.icon"></span> {{ element.title }}
            </div>

            <div class="sidebar-item" v-if="searchVal&&!filteredList().length">No user found!</div>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'

    let searchVal = ref('')

    export default {
        emits: ['navigation'],
        components: {

        },
        props: {
            header: String,
            menu: Object,
            home: Object,
            current: Number
        },
        setup() {
            return {
                searchVal
            }
        },
        data() {
            return {
                sidebarTitle: "",
                sidebarValues: []
            }
        },
        methods: {
            filteredList() {
                return this.menu.filter((element) => element.title.toLowerCase().includes(searchVal.value.toLowerCase()))
            }
        },
        created() {
        },
        mounted() {
        }
        
    }
</script>

<style scoped>
    .custom-sidebar {
        font-weight: bold;
        color: white;
        font-size: 1em;
        background-color: #2a2a2e;
    }

    .sidebar-item {
        text-align: left;
        background-color: #49494e;
        margin: 0 0 0.6em 0;
        padding: 0.75em 1em;
        color: white;
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
            rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    }

    .sidebar-item:hover {
        background-color: #67676e;
        cursor: pointer;
    }
    
    input {
        margin: 0 0 0.6em 0;
        width: 100%;
        padding: 0.75em 1em 0.75em 2em;
        display: block;
        background: white url("../assets/search.svg") no-repeat 15px center;
        background-size: 15px 15px;
        border: none;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }

    .sticky {
        top: 0;
        padding: 1em;
        position: -webkit-sticky; /* Safari & IE */
        position: sticky;
        overflow: scroll;
    }
</style>