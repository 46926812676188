<template>
    <div class="page">
        <div class="row justify-content-center" style="margin: 0; padding: 0;">
            <Transition name="medium" appear>
                <header class="row" style="padding: 0;">
                    <div class="col-sm-6">&nbsp;</div>
                    <div class="col-sm-6" style="padding: 0">
                        <p v-if="version !== null">{{ `Version ${version}` }}</p>
                        <div v-else class="outage">
                            <b class="warn"><span class="bi-exclamation-triangle"></span> Warning!</b>
                            <p>
                                Certain BxASSURE services are not currently available.
                                <br/>
                                <i>BxASSURE is currently unavailable due to scheduled maintenence.</i>
                            </p>
                        </div>
                    </div>
                    
                </header>
            </Transition>
            
            <div class="col-sm-5 home-sec-1">
                <img style="width: 90%; max-width: 700px;" src="../assets/logo.png" />
            </div>

            <Transition name="high" appear>
                <div class="col-sm-7 home-sec-2">
                    <h1>Test. Assess. Verify.</h1>
                    <p class="action">
                        Sharpen your identification abilities through self-paced scenario training
                    </p>
                    <button class="btn btn-danger btn-lg" style="font-size: large;" @click="requestLogin">Sign in</button>
                </div>
            </Transition>

            <Transition name="medium" appear>
                <footer>
                    <p><i>{{ copyright!==null ? copyright:'' }}</i></p>
                </footer>
            </Transition>
        </div>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store'
</script>

<script>
import axios from 'axios';
import Cookie from 'js-cookie'

export default {
    name: 'HomePage',
    components: {},
    data() {
        return {
            testList: testListStore(),
            version: null,
            copyright: null
        }
    },
    methods: {
        requestLogin() {
            window.open(`${process.env.VUE_APP_API}/login`, "_self")
        },
        tokenRequest() {
            axios.get(
                `${process.env.VUE_APP_API}/user/info`,
                {
                    withCredentials: true,
                }
            )
                .then(result => {
                    this.testList.email = result.data.email
                    this.testList.username = result.data.email.split("@")[0]
                    this.testList.token = this.getCookie()
                    this.testList.group = result.data.fields.group
                    axios.get(`${process.env.VUE_APP_API}/user/token`, { withCredentials: true })
                        .then(result => {
                            this.testList.$patch({
                                permissions: result.data.permissions
                            })
                            this.$router.push('dashboard/')
                        })
                })
                .catch(() => { })

        },
        getVersion() {
            axios.get(`${process.env.VUE_APP_API}/version`)
                .then(response => {
                    this.version = response.data.version
                    this.copyright = response.data.copyright
                })
        },
        getCookie() {
            return Cookie.get('user_session')
        }
    },
    mounted() {
        if (this.getCookie() != undefined) {
            this.tokenRequest()
        }
        this.getVersion()
    }
};
</script>

<style scoped lang="scss">

.page {
    min-height: 100vh;
    background-color: hsl(0, 0%, 98%);
}

.row {
    padding: 6em;
}

.home-sec-1 {
    background-image: url("../assets/background1.jpeg");
    background-size: cover;
    padding-top: 25vh;
    min-height: 100vh;
    box-shadow: 0 0 0.5em 1px rgb(0, 0, 0);
}

.home-sec-2 {
    padding-top: 25vh;
    min-height: 100vh;
}

.outage {
    margin: 1vh 1vw;
    border-radius: 2em;
    box-shadow: 0 0 1px 1px rgb(250, 234, 11);
    background-color: #fbf9e2;
    padding: 1vh 1vw 1vh 1vw;
    float: right;
    text-align: center;

    .warn {
        font-size: xx-large;
        .bi-exclamation-triangle {
            color: rgb(250, 166, 11);
        }
    }

    p {
        font-size: larger;
        margin: 0.5em 1em 1em 1em;
        padding: 0;
    }
}

.btn-danger {
    --bs-btn-bg: #fe363f;
}

/* .btn {
    --bs-btn-color: #fff;
    --bs-btn-bg: #5aaff1;
    --bs-btn-border-color: #5aaff1;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #72c2ff;
    --bs-btn-hover-border-color: #4ba9f1;
    --bs-btn-focus-shadow-rgb: 225, 83, 97;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #4ba9f1;
    --bs-btn-active-border-color: #4ba9f1;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #5aaff1;
    --bs-btn-disabled-border-color: #5aaff1;
} */

footer {
    position: fixed;
    left: 10px;
    bottom: 5px;
    right: 10px;
    text-align: right;
}

footer p {
    margin-left: 50%;
    font-size: 14px; 
    padding-right: 20px; 
    color: hsl(0, 0%, 40%)
}

header {
    position: fixed;
    left: 10px;
    right: 10px;
    text-align: right;
    
    p {    
        margin-top: 0.75em;
        margin-left: 50%;
        font-size: 14px; 
        padding: 0 20px 0 0; 
        color: hsl(0, 0%, 40%)
    }
}

.action {
    font-size: x-large;
    color: hsl(0, 0%, 30%);
    padding: 40px 8vw;
}
</style>