<template>
    <div class="page">
        <vue-basic-alert :duration="300" :closeIn="7000" ref="alert" />

        <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="false" :loader="'dots'" :color="'#ff0000'"
            :height="200" :width="200" :z-index="2" />

        <Transition name="high" appear>
            <h1 style="padding-top: 30px;">Assign Test</h1>
        </Transition>

        <Transition name="custom" style="--opacity_ease_active: 4s; --transform_ease_active: 2s; --transform_leave_active:0px; --opacity_ease_to:0; --transform_leave_to:100px "
            appear>
            <div v-if="userTable.length != 0" style="width: 50%; margin: 0 auto;">
                <h2>User Test Assignments</h2>
                <EasyDataTable
                    :headers="headerTable"
                    :items="userTable"
                    :alternating="true"
                />
            </div>
        </Transition>

        <Transition name="medium" appear>
            <div>
                <h2>Select Tests</h2>
                <div class="multiselect-container">
                    <VueMultiselect v-model="selected_tests" :options="tests" :multiple="true" :close-on-select="true"
                        :disabled="tests.length == 0" label="name" track-by="_id" />
                    <p v-if="tests.length == 0" style="color:#dc3545; padding-top: 2em;">
                        <b>Warning! You have no owned tests to assign to users.</b>
                    </p>
                </div>
                
                <div v-if="selected_tests.length > 0">
                    <h2>Select Users</h2>
                    <div class="multiselect-container">
                        <VueMultiselect v-model="selected_users" :options="filtered_users" :multiple="true"
                            :disabled="users.length == 0" :close-on-select="true" label="username" track-by="_id" />
                    </div>
                    <p v-if="users.length == 0" style="color:#dc3545; padding-top: 2em;">
                        <b>Warning! You do not manage any users.</b>
                    </p>
                </div>

                <div v-if="selected_tests.length > 0 && selected_users.length > 0">
                    <h2>Select Due Date</h2>
                    <DatePicker borderless v-model="due_date" />
                </div>

                <div v-if="selected_tests.length > 0 && selected_users.length > 0" class="form-check"
                    style="margin: 30px auto 0 auto; padding: 0;">
                    <input v-model="retry" type="checkbox">
                    <label class="form-check-label" for="flexCheckDefault">
                        <b @click="retry = !retry" style="cursor:pointer">Allow multiple test attempts</b>
                    </label>
                </div>

                <button v-if="selected_tests.length > 0 && selected_users.length > 0" class="btn btn-danger"
                    style="margin-bottom: 50px;" @click="assignTests()">Assign</button>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store'
</script>


<script>
import axios from 'axios'
// import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import VueMultiselect from 'vue-multiselect'
import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'
import { ref, toRaw } from 'vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

export default {
    name: 'AssignTestPage',
    components: {
        // VueJsonPretty,
        VueMultiselect,
        DatePicker,
        Loading
    },
    data() {
        return {
            testList: testListStore(),
            headerTable: [],
            userTable: [],
            users: [],
            filtered_users: [],
            tests: [],
            selected_tests: [],
            selected_users: [],
            due_date: ref(new Date()),
            retry: false,
            isLoading: false
        }
    },
    created() {
        this.isLoading = true
        const all_promises = [this.getUsers(), this.getTests()]
        Promise.all(all_promises)
            .then(() => {
                this.getUserTable()
            })
            .catch(() => {})
            .finally(() => {
                this.isLoading = false
            })
    },
    watch: {
        selected_tests(updated) {
            let test_list = toRaw(updated)
            let user_list = toRaw(this.users)

            let selected_test_ids = []
            let eliminate_users = []

            test_list.forEach(element => {
                selected_test_ids.push(element["_id"])
            })

            user_list.forEach(element => {
                element['assigned'].forEach(element2 => {
                    if (selected_test_ids.includes(element2["test"]) && !eliminate_users.includes(element)) {
                        eliminate_users.push(element)
                    }
                })
            })

            this.filtered_users = user_list.filter(element => !eliminate_users.includes(element))
        }
    },
    methods: {
        getUsers() {
            const promise = axios.get(
                `${process.env.VUE_APP_API}/manage/participants/`,
                {
                    withCredentials: true
                }
            )
                .then(response => {
                    this.users = response.data
                    this.filtered_users = response.data
                })
                .catch(error => {
                    // Only show not 'not found' error
                    if (error.response.status != 404) {
                        this.$refs.alert.showAlert(
                            'error',
                            'Got an error when attempting to retrieve users',
                            `Failed to get User List`,
                            {
                                iconSize: 35,
                                iconType: 'solid',
                                position: 'bottom right'
                            }
                        )
                    }
                    this.isLoading = false
                })
            return promise
        },
        getTests() {
            const promise = axios.get(
                `${process.env.VUE_APP_API}/manage/tests/?owner=${this.testList.username}`,
                {
                    withCredentials: true
                }
            )
                .then(response => {
                    this.tests = response.data
                })
                .catch(error => {
                    // Only show not 'not found' error
                    if (error.response.status != 404) {
                        this.$refs.alert.showAlert(
                            'error',
                            'Got an error when attempting to retrieve tests',
                            `Failed to get Test List`,
                            {
                                iconSize: 35,
                                iconType: 'solid',
                                position: 'bottom right'
                            }
                        )
                    }
                    
                    this.isLoading = false
                })
            return promise
        },
        assignTests() {
            this.isLoading = true
            const promises = []
            for (let i = 0; i < this.selected_users.length; i++) {
                let user = this.selected_users[i]
                let assigned = []
                for (let j = 0; j < this.selected_tests.length; j++) {
                    let test = this.selected_tests[j]

                    assigned.push({
                        "due": this.due_date,
                        "retry": this.retry,
                        "test": test._id
                    })
                }
                // Assign test to user
                const promise = axios.put(`${process.env.VUE_APP_API}/manage/participants/${user.username}/add`,
                    {
                        "assigned": assigned
                    },
                    {
                        withCredentials: true
                    }
                )
                    .catch(() => {
                        this.isLoading = false
                    })


                promises.push(promise)
            }
            Promise.all(promises)
                .then(() => {
                    this.$refs.alert.showAlert(
                        'success',
                        'Completed processing the tests for the given users.',
                        'Successfully Assigned',
                        {
                            iconSize: 35,
                            iconType: 'solid',
                            position: 'bottom right'
                        }
                    )

                    this.selected_users = []
                    this.selected_tests = []

                    const all_promises = [this.getUsers(), this.getTests()]
                    Promise.all(all_promises)
                        .then(() => {
                            this.updateUserInfo()
                            
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                })
        },
        getUserTable() {
            this.headerTable = [
                { text: 'Name', value: 'user', fixed: true},
                { text: 'Username', value: 'email', width: 150}
            ]

            this.tests.forEach(test => {
                this.headerTable.push({
                    text: test.name,
                    value: test['_id'],
                    width: 150
                })
            })

            this.updateUserInfo()
        },
        updateUserInfo() {
            const table_content = []

            this.users.forEach(user => {
                let user_row = {
                    'user': user.username,
                    'email': user.email
                }
                
                this.tests.map(test => test['_id']).forEach(filtered => {
                    user_row[filtered] = user.assigned.map(x => x.test).includes(filtered)?'Assigned':'Not Assigned'
                })
                table_content.push(user_row)
            })

            this.userTable = table_content
        }
    }
};
</script>

<style scoped>
h2 {
    margin: 60px 0 30px 0;
}

.btn {
    margin: 30px 0;
}
</style>
