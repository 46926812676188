<template>
    <div v-if="test_new" class="new_message">
        <span class="bi-patch-exclamation-fill patch"></span>
        <b class="hide">Newly Assigned Test</b>
    </div>
    <div style="height: 15%;">
        <h2 class="vertical-center">{{ test_name }}</h2>
    </div>
    <div class="circle-container">
        <circle-progress :percent="progress" :border-width="20" :border-bg-width="20" :transition="800" :viewport="true"
            :show-percent="!test_new" :fill-color="determineFillColour(test_status)"
            :empty-color="determineEmptyColour(test_status)" :unit="determineMessage(test_new)" class="center" />
    </div>
    <div>
        <p>
            {{ test_status !== 2 ? `Due: ${test_due}` : `Completed: ${test_due}` }}
        </p>
    </div>
    <div v-if="test_new">
        <button style=" margin-top: 10px;" type="button" class="btn btn-success justify-content-center"
            @click="$emit('redirectToTest')">
            <span>
                Start
            </span>
        </button>
    </div>
    <div v-else-if="test_status === 2">
        <button style=" margin-top: 10px;" type="button" class="btn btn-success justify-content-center"
            @click="$emit('redirectToResults')">
            <span>
                View Results
            </span>
        </button>
    </div>
    <div v-else>
        <button style=" margin-top: 10px;" type="button" class="btn btn-primary justify-content-center"
            @click="$emit('redirectToTest')">
            <span>
                Continue
            </span>
        </button>
    </div>
</template>

<script>
import axios from "axios"
import CircleProgress from "../components/CircleProgress.vue"
export default {
    name: 'TestCard',
    components: {
        CircleProgress
    },
    emits: ['redirectToTest', 'redirectToResults'],
    props: {
        test_status: {
            type: Number,
            default: 0
        },
        test_name: {
            type: String
        },
        test_due: {
            type: String
        },
        test_progress: {
            type: Array,
            default() {
                return [];
            }
        },
        test_new: { // Flag for new tests
            type: Boolean,
            default: false
        },
        test_id: {
            type: String
        }
    },
    data() {
        return {
            progress: 0
        }
    },
    methods: {
        // TODO! Combine the Colour Methods Below
        determineFillColour(status) {
            if (status === 2) {
                return "#35db61"
            } else {
                return "#3d44ff"
            }

        },
        determineEmptyColour(status) {
            if (status === 2) {
                return "#35db61"
            } else if (status === -1) {
                return "#cfcfcf"
            } else {
                return "#9cc2ff"
            }
        },
        determineMessage(new_flag) {
            if (new_flag) {
                return 'Not Started'
            } else {
                return '% Complete'
            }
        },
        calculateProgress() {
            let data = {
                overallCandidate: 0,
                overallProgression: 0
            }
            const promises = []
            for (const progression of this.test_progress) {
                data['overallProgression'] += (progression.pos.length + progression.inc.length + progression.neg.length)
                const promise = axios.get(`${process.env.VUE_APP_API}/session/test/${this.test_id}/questions/${progression.question}`, { withCredentials: true })
                    .then(results => {
                        data['overallCandidate'] += results.data.candidates.length
                    })
                promises.push(promise)
            }

            Promise.all(promises)
                .then(() => {
                    const progress = (data.overallProgression / data.overallCandidate) * 100
                    if (progress > 100) { // Error with calculation, temporary bugfix
                        this.progress = 100
                    }
                    this.progress = progress
                })

        }
    },
    computed: {
        cssProps() {
            return {

            }
        }
    },
    mounted() {
        if (this.test_status == 2) {
            this.progress = 100
        } else if (!this.test_new) {
            this.calculateProgress()
        }

    }
}
</script>

<style scoped>
p {
    color: hsl(0, 0%, 35%);
    font-size: 14px;
    font-style: oblique;
}

h2 {
    color: hsl(0, 0%, 35%);
    font-size: 22px;
    font-weight: bold;
}

.circle-container {
    color: hsl(0, 0%, 35%);
    font-size: 15px;
    font-weight: bold;
    height: 70%;
}

.new_message {
    position: absolute;
    top: 1em;
    left: 1em;
}

.hide {
    visibility: hidden;
    padding: 0 1em 0 1em;
}

.patch {
    font-size: 24px;
    color: #198754;
    cursor: pointer;
}

.patch:hover+.hide {
    visibility: visible;
    color: #198754;
    border-radius: 10px;
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-left: 1vw;
    padding: 1vh 1vw 1vh 1vw;
}
</style>