<template>
    <draggable class="grid" :list="images" itemKey="name" :group="group" :tiles="tiles" @remove="$emit('draggableRemove')"
        @add="$emit('draggableAdd')" :style="{ minHeight: '150px' }">
        <template v-if="disable_viewer" #item="{ element }">
            <div class="viewer">
                <img class="image" :key="`draggable-${element.id}`"
                    :src="Object.keys(imageBlobs).length === 0 ? [`${url}${element}`] : imageBlobs[element]"
                    :data-source="Object.keys(imageBlobs).length === 0 ? [`${url}${element}`] : imageBlobs[element]" :style="{
                        maxWidth: tiles == 2 ? '30vmin' : '20vmin'
                    }" alt='Candidate' @click="$emit('imageSelected', element)">
            </div>
        </template>
        <template v-else #item="{ element }">
            <ViewerComp ref="viewer" :options="options"
                :images="[Object.keys(imageBlobs).length===0?[`${url}${element}`]:imageBlobs[element]]" class="viewer"
                @inited="inited">
                <img class="image" :key="`draggable-${element.id}`"
                    :src="Object.keys(imageBlobs).length===0?[`${url}${element}`]:imageBlobs[element]"
                    :data-source="Object.keys(imageBlobs).length===0?[`${url}${element}`]:imageBlobs[element]"
                    alt='Candidate'>
            </ViewerComp>
        </template>
    </draggable>
</template>

<script>
// TODO -> Bind :move="()=>{return false}" to draggable when you want to prevent dragging
import draggable from 'vuedraggable'
import ViewerComp from "../components/ViewerComp.vue"

export default {
    emits: [
        'draggableRemove',
        'draggableAdd',
        'imageSelected'
    ],
    props: {
        images: {
            type: Array,
        },
        url: {
            type: String,
            default: ""
        },
        group: {
            type: String,
            default: "organise"
        },
        tiles: {
            type: Number,
            default: 4
        },
        img_width: {
            default: '100%'
        },
        imageBlobs: {
            type: Object,
            default() {
                return {};
            }
        },
        disable_viewer: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ViewerComp,
        draggable
    },
    data() {
        return {
            options: {
                inline: false,
                button: true,
                navbar: true,
                title: true,
                toolbar: {
                    oneToOne: true,
                    leftRotate: () => {
                        this.$viewer.rotate(10);
                    },
                    rightRotate: () => {
                        this.$viewer.rotate(-10);
                    },
                    reset: true,
                    customZoom: () => {
                        this.$viewer.zoom(1)
                    }
                },
                tooltip: true,
                movable: true,
                zoomable: true,
                rotatable: true,
                scalable: true,
                transition: false,
                fullscreen: true,
                keyboard: true,
                url: 'data-source',
            }
        }
    },

    computed: {
    },

    watch: {

    },
    mounted() {

    },
    unmounted() {

    },
    methods: {
        inited(viewer) {
            this.$viewer = viewer
        },
    },
}
</script>

<style scoped>
img {
    cursor: pointer;
    width: v-bind(img_width);
    border-radius: 10px;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.3);
    /* max-width of image is defined in the binded style */
}

.grid {
    display: grid;
    padding:1.5em;
    grid-template-columns: repeat(v-bind(tiles), auto);
    grid-gap: 20px;
    align-items: center;
}
</style>