<template>
    <div>
        <slot :images="images" :options="options"></slot>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import Viewer from 'viewerjs';

    export default {
        props: {
            images: {
                type: Object,
            },
            rebuild: {
                type: Boolean,
                default: false,
            },
            trigger: {},
            options: {
                type: Object,
            },
            emit_index: {
                type: Boolean,
                default: false,
            }
        },

        data() {
            return {
            }
        },

        computed: {
        },

        watch: {
            images() {
                this.$nextTick(() => {
                    this.onChange()
                })
            },
            trigger: {
                handler() {
                    this.$nextTick(() => {
                        this.onChange()
                    })
                },
                deep: true,
            },
            options: {
                handler() {
                    this.$nextTick(() => {
                        this.rebuildViewer()
                    })
                },
                deep: true,
            },
        },
        mounted() {
            this.createViewer()
            if(this.emit_index) {
                const view_instance = this.$viewer.view()
                this.$emit('indexInstance', view_instance.index)
            }
        },
        unmounted() {
            this.destroyViewer()
        },
        methods: {
            onChange() {
                if (this.rebuild) {
                    this.rebuildViewer()
                }
                else {
                    this.updateViewer()
                }
            },
            rebuildViewer() {
                this.destroyViewer()
                this.createViewer()
            },
            updateViewer() {
                if (this.$viewer) {
                    this.$viewer.update()
                    this.$emit('inited', this.$viewer)
                }
                else {
                    this.createViewer()
                }
            },
            destroyViewer() {
                this.$viewer && this.$viewer.destroy()
            },
            createViewer() {
                this.$viewer = new Viewer(this.$el, this.options)
                this.$emit('inited', this.$viewer)
            },
        },
    }
</script>

<style>
.viewer-right-rotate, .viewer-left-rotate, .viewer-custom-zoom {
    color: #fff;
    font-family: FontAwesome, serif;
    font-size: 1.5rem;
    line-height: 1.25rem;
    font-weight: 700;
}

.viewer-right-rotate::before {
    content: "⤣";
    text-align: left;
}

.viewer-left-rotate::before {
    content: "⤤";
    text-align: right;
}

.viewer-custom-zoom::before {
    content: "⤢";
    line-height: 1.4rem;
    padding-left: 0.05em;  
}

</style>