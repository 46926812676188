<template>
    <div class="page">
      <div style="padding-top: 10em;">
        <Transition name="high" appear>
          <h1>404 - Requested page did not exist.</h1>
        </Transition>
  
        <Transition name="medium" appear>
          <p style="padding-bottom: 1em;">
            The page which you requested could not be found!
          </p>
        </Transition>
      </div>    
    </div>
  </template>
  
  <script>
    export default {
      name: 'NotFoundPage',
      components: {},
      data() {
        return {
        }
      },
    };
  </script>
  
  <style scoped>
  
  </style>