<template>
    <Transition name="high" appear>
        <div
            class="center-screen" 
            style=""
        >
            <h1
                style="padding-top: 1em;"
            >
                Confirm Test Submission
            </h1>
            <p>
                Are you sure you want to submit your test?
            </p>
            <u>
                <p v-if="!test_flags.every(current => current==false)">
                    Warning! 
                    <br />
                    You have {{ test_flags.filter(flag => flag).length }} {{ test_flags.filter(flag => flag).length==1?'question': 'questions'}} which you have left as flagged.
                    <br />
                    Ensure you are satisfied with your answers before submitting.
                </p>
            </u>
            <p>
                <i>Once you have submitted your test you cannot alter your results.</i>
            </p>
            <div style="display:inline-block; padding-top: 1em;">
                <button 
                    type="button" 
                    class="btn btn-secondary btn-lg " 
                    style="margin: 0 60px; margin-top: 1em;"
                    @click="$emit('cancelSubmission')"
                >
                    Cancel
                </button>
                <button 
                    type="button" 
                    class="btn btn-success btn-lg" 
                    style="margin: 0 60px; margin-top: 1em;"
                    @click="$emit('confirmSubmission')"
                >
                    Submit
                </button>
            </div>
        </div>
    </Transition>
</template>

<script>
    export default {
        name: 'TestEnd',
        emits: ['cancelSubmission', 'confirmSubmission'],
        props: {
            test_flags: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data() {
            return {
                local_flags: []
            }
        },
        created() {
            if (this.test_flags.length > 0) {
                if (this.test_flags[0] == null) {
                    const local = this.test_flags
                    local.splice(0, 1)
                    this.local_flags = local
                }
            }
        }
    }
</script>

<style scoped>
.center-screen {
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    padding: 0.5em 0.5em 0.5em 0.5em;
    height: 25em;
    width: 50%;
    border: 3px solid rgb(203, 203, 203);
    background-color: #fff; 
  }

</style>