<template>
  <nav class="navbar navbar-expand-lg">
    <router-link class="navbar-brand" to="/dashboard" title="Navigate to your dashboard">
      BxASSURE <span class="bi-house-fill"></span>
    </router-link>

    <ul class="navbar-nav">
      <router-link class="nav-item" to="/list" title="View all of your tests">
        Tests
      </router-link>
    </ul>
    <ul v-if="testList.permissions.includes('admin:write')" class="navbar-nav" title="Navigate to the user management page">
      <router-link class="nav-item" to="/users">
        Users
      </router-link>
    </ul>
    <ul v-if="testList.permissions.includes('super:write')" class="navbar-nav" title="Navigate to the test creation page">
      <router-link class="nav-item" to="/createtest">
        Create
      </router-link>
    </ul>
    <ul v-if="testList.permissions.includes('admin:write')" class="navbar-nav" title="Navigate to the test assignment page">
      <router-link class="nav-item" to="/assigntest">
        Assign
      </router-link>
    </ul>

    <div style="margin-left: auto;"> 
      <!-- <div class="btn-group" role="group">
        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title="Notifications">
          <span class="bi-bell-fill"></span>
        </button>

        <ul class="dropdown-menu dropdown-menu-end bg-light">
          <li><a class="dropdown-item" style="pointer-events: none;">No current notifications</a></li>
        </ul>
      </div> -->

      <div class="btn-group" role="group">
        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title="Calendar">
          <span class="bi-calendar-check-fill"></span>
        </button>

        <div
          class="dropdown-menu dropdown-menu-end bg-light"
          onclick="event.stopPropagation();"
          style="padding: 0;"
        >
          <Calendar :attributes="addDates()" />
        </div>
      </div>

      <div class="btn-group" role="group">
        <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" title="User">
          <span class="bi-person-fill"></span>
        </button>

        <ul class="dropdown-menu dropdown-menu-end bg-light">
          <li class="dropdown-item" style="text-align: center; pointer-events: none;">
            {{ testList.email }}
          </li>
          <hr style="margin: 0.25em 0 0.5em 0">
          <li><a class="dropdown-item" style="cursor:pointer" @click="passwordPage">Update Password</a></li>
          <li><a class="dropdown-item" style="cursor:pointer" @click="logOut">Logout</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
  import { testListStore } from '../stores/store'
</script>

<script>
  // import axios from 'axios';
  import { Calendar } from 'v-calendar';
  
  export default {
    name: 'NavBar',
    
    components: {
      Calendar
    },
    methods: {
      logOut() {
        window.open(`${process.env.VUE_APP_API}/logout`, "_self")
      },
      passwordPage() {
        window.open(`${process.env.VUE_APP_AUTH}/password`, "_blank")
      },
      addDates() {
        const local_list = [
          {
            highlight: {
              color: 'red',
              fillMode: 'solid',
              contentClass: 'italic',
            },

            dates: new Date(),

            popover: {
              label: "Today"
            }
          }
        ]

        for (const test of this.testList.tests) {
          local_list.push({
            highlight: {
              color: 'blue',
              fillMode: 'solid',
              contentClass: 'italic',
            },

            dates: new Date(test['due']),

            popover: {
              label: `Due: ${test.test.name} (Started)`
            }
          })
        }

        for (const test of this.testList.newTests) {
          local_list.push({
            highlight: {
              color: 'gray',
              fillMode: 'solid',
              contentClass: 'italic',
            },

            dates: new Date(test['due']),

            popover: {
              label: `Due: ${test.name} (Not Started)`
            }
          })
        }
        for (const test of this.testList.completed) {
          local_list.push({
            highlight: {
              color: 'green',
              fillMode: 'solid',
              contentClass: 'italic',
            },

            dates: new Date(test['due']),

            popover: {
              label: `Due: ${test.test.name} (Completed)`
            }
          })
          
        }
        return local_list
      }
    },
    mounted() {
    },
    data() {
      return {
        testList: testListStore(),
        logoutURL: `${process.env.VUE_APP_API}/logout`
      };
    }
  };
</script>

<style scoped lang="scss">
  .navbar {
    padding: 20px;
    background-color: hsl(0, 0%, 95%);
    height: 8vh;
    color: hsl(0, 0%, 25%);
  }
  
  .navbar-brand {
    font-weight: bold;
    color: #dd1924;
  }
  .navbar-brand:hover {
    color: #dd1924;
  }
  .navbar-brand:focus {
    color: #dd1924;
  }

  .dropdown-toggle {
    border: none;
  }

  .dropdown-menu {
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.5em;
  }

  .nav-item {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    margin-top: 0.1vh;
  }

  ul {
    padding-right: 1em;
  }

  .dropdown-item {
    font-size: 15px;
  }
</style>