<template>
    <div class="col-sm-1">
        <circle-progress
            :size="50"
            :percent="progress"
            :border-width="7"
            :border-bg-width="7"
            :transition="800"
            :viewport="true"
            :fill-color="determineFillColour(test_status)"
            :empty-color="determineEmptyColour(test_status)"
        />
    </div>

    <div class="col-sm-5" style="text-align: left;">
        <h4>{{ test_name }}</h4>
        <p>{{ test_info }}</p>
    </div>
    
    <div class="col-sm-2" style="text-align: left; margin-top: 10px;">
        <p v-if="test_status === 2"><b>Completed:</b> {{ test_due.toString().split('T')[0] }}</p>
    </div>

    <div class="col-sm-2" style="text-align: left; margin-top: 10px;">
        <p v-if="test_status === 2"><b>Score:</b> {{ results }}%</p>
        <p v-else><b>Due:</b> {{ test_due.toString().split('T')[0] }}</p>
    </div>

    <div class="col-sm-2">
        <button 
            v-if="test_status === -1"
            style=" margin-top: 7px;" 
            type="button" 
            class="btn btn-success justify-content-center"
            @click="$emit('redirectToTest')"
        >
            Start
        </button>
        <button 
            v-else-if="test_status === 2"
            style="margin-top: 7px;" 
            type="button" 
            class="btn btn-success justify-content-center"
            @click="$emit('redirectToResults')"
        >
            View Results
        </button>
        <button 
            v-else
            style=" margin-top: 7px; color: white;" 
            type="button" 
            class="btn btn-primary justify-content-center"
            @click="$emit('redirectToTest')"
        >
            Continue
        </button>
    </div>
</template>

<script>
    import "vue3-circle-progress/dist/circle-progress.css";
    import CircleProgress from "vue3-circle-progress";
    import axios from "axios";

    export default {
        name: 'TestListContent',
        emits: ['redirectToTest', 'redirectToResults'],
        components: {
            CircleProgress,
        },
        props: {
            test_name: {
                type: String
            },
            test_info: {
                type: String
            },
            test_status: {
                type: Number
            },
            test_due: {
                type: String
            },
            test_progress: {
                type: Array,
                default() {
                    return [];
                }
            },
            results: {
                type: String,
                default: ""
            },
            real_test_id: {
                type: String
            }
        },
        data() {
            return {
                progress: 0
            }
        },
        methods: {
            // TODO! Combine the Colour Methods Below
            determineFillColour(status) {
                if (status === 2) {
                    return "#35db61"
                } else {
                    return "#3d44ff"
                }
            },
            determineEmptyColour(status) {
                if (status === 2) {
                    return "#35db61"
                } else if (status === -1) {
                    return "#cfcfcf"
                } else {
                    return "#9cc2ff"
                }
            },
            calculateProgress() {
                let data = {
                    overallCandidate: 0,
                    overallProgression: 0
                }
                const promises = []
                for(const progression of this.test_progress) {
                    data['overallProgression'] += (progression.pos.length + progression.inc.length + progression.neg.length)
                    const promise = axios.get(`${process.env.VUE_APP_API}/session/test/${this.real_test_id}/questions/${progression.question}`,{withCredentials:true})
                        .then(results => {
                            data['overallCandidate'] += results.data.candidates.length
                        })
                    promises.push(promise)
                }

                Promise.all(promises)
                    .then(()=>{
                        this.progress = (data.overallProgression/data.overallCandidate)*100
                    }        
                )

            }
        },
        mounted() {
            if (this.test_status == 2) {
                this.progress = 1
            } else if (this.test_status !== -1) {
                this.calculateProgress()
            } else {
                this.progress = 0 
            }

            
        }
    }
</script>

<style scoped lang="scss">
    h4 {
        font-size: 18px;
        font-weight: bold;
    }

    p {
        font-size: 14px;
    }
</style>