import { defineStore } from 'pinia'

export let testListStore = defineStore('testList', {
    state: () => ({
        token: null,
        permissions: [],
        username: null,
        group: null,
        email: null,
        selectedTest: null,
        selectedTestNew: false,
        newTests: [],
        tests: [],
        completed: [],
        managedTests: [],
        givenResults: {},
        popUpInfo: {}
    })
})