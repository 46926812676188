<template>
    <div class="page">
        <vue-basic-alert :duration="300" :closeIn="7000" ref="alert" />

        <Transition name="high" appear>
            <h1 :style="conditionallyBlur"> User Management</h1>
        </Transition>

        <Transition name="medium" appear>
            <div class="tabs">
                <input class="input" :style="currentVars" name="tabs" type="radio" id="tab-1" :checked="single_add"
                    :disabled="confirmMessage" />
                <label class="label" :style="currentVars" for="tab-1"
                    title="Navigate to the individual user creation page.">
                    Add User
                </label>
                <div class="panel">
                    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="false" :loader="'dots'"
                        :color="'#ff0000'" :height="200" :width="200" :z-index="2" />

                    <p style="margin-top: 20px;">
                        Add users one at a time
                    </p>
                    <div class="input-div">
                        <h2>Username</h2>
                        <input class="form-control" style="width: 30vw;" v-model="username"
                            placeholder="Enter the new user's username here" />
                    </div>
                    <div class="input-div">
                        <h2>Password</h2>
                        <input class="form-control" style="margin-bottom: 20px; width: 30vw;" v-model="password"
                            placeholder="Enter the new user's password here" :type="hide_password ? 'text' : 'password'" />
                        <p v-if="password.length < 8 && password.length != 0" style="color:#dc3545">
                            Please ensure that the password is at least 8 characters long.
                        </p>
                        <input v-model="hide_password" type="checkbox" > <!-- class="form-check-input" id="flexCheckDefault" -->
                        <label class="form-check-label" for="flexCheckDefault">
                            <b @click="hide_password=!hide_password" style="cursor:pointer">Show Password</b>
                        </label>
                    </div>
                    <button class="btn btn-danger" @click="submitUser(username, password)" type="submit"
                        :disabled="username.length == 0 || password.length < 8">
                        Add User
                    </button>
                </div>
                <!-- <input class="input" :style="currentVars" name="tabs" type="radio" id="tab-2" :disabled="confirmMessage"/>
                <label class="label" :style="currentVars" for="tab-2" title="Navigate to the mass user creation page.">
                    <p>
                        Mass Add Users
                    </p>
                </label>
                <div class="panel">
                    <p>
                        Using a comma-separated values (csv) file, mass upload multiple users at once.
                    </p>
    
                    <input class="form-control" type="file" accept=".csv" @change="handleFileUpload( $event )"/>
                    
                    <div class="df-container">
                        <table class="table table-light table-bordered" v-if="parsed">
                            <thead>
                                <tr>
                                    <th v-for="(header, key) in content.meta.fields" v-bind:key="'header-' + key">
                                        {{ header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, rowKey) in content.data" v-bind:key="'row-' + rowKey">
                                    <td v-for="(column, columnKey) in content.meta.fields" v-bind:key="'row-'+rowKey+'-column-' + columnKey">
                                        <input v-model="content.data[rowKey][column]"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
    
                    <button class="btn btn-danger">Add</button>
                </div> -->
                <input class="input" :style="currentVars" name="tabs" type="radio" id="tab-3" :disabled="confirmMessage"
                    @click="getAllUsers()" />
                <label class="label" :style="currentVars" for="tab-3" title="Navigate to the user removal page.">
                    Remove Users
                </label>
                <div class="panel">
                    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="false" :loader="'dots'"
                        :color="'#ff0000'" :height="200" :width="200" :z-index="2" />

                    <div v-if="confirmMessage" class="confirm-container">
                        <h4>Confirm User Deletion</h4>
                        <p>
                            Please confirm that you wish to delete the following users:
                        </p>
                        <div class="user-list">
                            <ul v-for="user in selectedUsers" :key="user._id">
                                <li>{{ user.username }} (email: {{ user.email }})</li>
                            </ul>
                        </div>

                        <div style="display:inline-block;">
                            <button type="button" class="btn btn-secondary btn-lg " style="margin: 0 60px; margin-top: 1em;"
                                @click="confirmMessage = !confirmMessage">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-success btn-lg" style="margin: 0 60px; margin-top: 1em;"
                                @click="removeSelectedUsers(selectedUsers)">
                                Submit
                            </button>
                        </div>
                    </div>

                    <div :style="conditionallyBlur">
                        <p style="margin: 20px 0;">
                            Remove one or multiple users at once
                        </p>
                        <div class="multiselect-container">
                            <VueMultiselect v-model="selectedUsers" :options="allUsers" :multiple="true"
                                :close-on-select="true" label="username" track-by="_id" />
                        </div>

                        <button class="btn btn-danger" title="Click here to remove users."
                            :disabled="selectedUsers.length < 1 || confirmMessage"
                            @click="confirmMessage = !confirmMessage">Remove
                            Users</button>
                    </div>

                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store'
</script>

<script>
import axios from 'axios';
import VueMultiselect from 'vue-multiselect'
import Papa from 'papaparse';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name: 'UserAddPage',
    components: {
        VueMultiselect,
        Loading
    },
    data() {
        return {
            testList: testListStore(),
            username: "",
            password: "",
            selectedUsers: [],
            allUsers: [],
            removeFails: [],
            removeSuccess: [],
            confirmMessage: false,
            single_add: true,
            hide_password: false,
            file: '',
            content: [],
            parsed: false,
            isLoading: false
        }
    },
    methods: {
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.parseFile();
        },
        parseFile() {
            Papa.parse(this.file, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    this.content = results;
                    this.parsed = true;
                }.bind(this)
            });
        },
        getAllUsers() {
            this.isLoading = true
            axios.get(`${process.env.VUE_APP_API}/manage/participants/`, { withCredentials: true })
                .then(response => {
                    this.allUsers = response.data
                    this.isLoading = false
                })
                .catch(() => {
                    this.$refs.alert.showAlert(
                        'error',
                        'Got an error when attempting to retrieve users',
                        `Failed to get User List`,
                        {
                            iconSize: 35,
                            iconType: 'solid',
                            position: 'bottom right'
                        }
                    )
                    this.isLoading = false
                    this.allUsers = []
                })
        },
        removeUser(uid) {
            const promise = axios.delete(`${process.env.VUE_APP_API}/manage/participants/${uid}`, { withCredentials: true })
                .then(() => this.removeSuccess.push(uid))
                .catch(() => this.removeFails.push(uid))
            return promise
        },
        removeSelectedUsers(selectedUsers) {
            this.isLoading = true
            const all_promises = []
            selectedUsers.forEach(user => all_promises.push(this.removeUser(user.username)))
            Promise.all(all_promises)
                .then(() => {
                    if (this.removeSuccess.length > 0) {
                        this.$refs.alert.showAlert(
                            'success',
                            `Successfully removed the following users: ${this.removeSuccess.join(', ')}`,
                            `Users Removed`,
                            {
                                iconSize: 35,
                                iconType: 'solid',
                                position: 'bottom right'
                            }
                        )
                        this.removeSuccess = []
                    }
                    if (this.removeFails.length > 0) {
                        this.$refs.alert.showAlert(
                            'error',
                            `Got an error when attempting to remove the following users: ${this.removeFails.join(', ')}`,
                            `Error! Removal Failed`,
                            {
                                iconSize: 35,
                                iconType: 'solid',
                                position: 'bottom right'
                            }
                        )
                        this.removeFails = []
                    }
                    this.getAllUsers() // 
                    this.confirmMessage = false
                    this.selectedUsers = []
                    this.isLoading = false
                })
        },
        submitUser(username, password) {
            this.isLoading = true
            axios.post(
                `${process.env.VUE_APP_API}/manage/participants/`,
                {
                    "username": username,
                    "group": this.testList.group,
                    "pwd": password
                },
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(() => {
                    this.$refs.alert.showAlert(
                        'success',
                        `Successfully created the new user '${username}'`,
                        `User Created`,
                        {
                            iconSize: 35,
                            iconType: 'solid',
                            position: 'bottom right'
                        }
                    )
                    this.isLoading = false
                    this.username = ''
                    this.password = ''
                })
                .catch(error => {
                    let reason = error?.response?.data?.reason // Add error response from requestResponse body
                    if (Array.isArray(reason)) {
                        reason = reason.join(" ")
                    }
                    this.$refs.alert.showAlert(
                        'error',
                        `Could not created the new user '${username}'. ${reason!=undefined?reason:''}`,
                        `Error! User Creation Failed`,
                        {
                            iconSize: 35,
                            iconType: 'solid',
                            position: 'bottom right'
                        }
                    )
                    this.isLoading = false
                })

        }
    },
    computed: {
        currentVars() {
            return {
                '--bg-color': '#fff',
                filter: this.confirmMessage ? 'blur(0.25rem)' : 'blur(0)'
            }
        },
        conditionallyBlur() {
            return {
                filter: this.confirmMessage ? 'blur(0.25rem)' : 'blur(0)'
            }
        }
    }
};
</script>

<style scoped>
h1 {
    padding-top: 30px;
    margin-bottom: 50px;
}

h2 {
    margin: 40px 0 20px 0;
}

.btn {
    margin: 40px 0;
}

input {
    border: none;
    margin: 0 auto;
    padding: 10px 15px;
    color: hsl(0, 0%, 30%);
}

input:focus {
    color: hsl(0, 0%, 30%);
}

.df-container {
    overflow: auto;
    height: 500px;
    max-width: 900px;
    margin: 40px auto;
    text-align: left;
    font-size: 14px;
}

.df-container input {
    border: none;
    color: inherit;
    background-color: inherit;
}

.form-control {
    width: 300px;
    margin: auto;
    font-size: 14px;
}

.enableBlur>* {
    filter: blur(0);
}

.disableBlur {
    filter: blur(0);
}

.confirm-container {
    background-color: #fff;
    margin-top: 1em;
    padding: 25px 40px;
    margin: auto;
    width: 50%;
    border: 3px solid rgb(203, 203, 203);
    border-radius: 10px;
    position: absolute;
    top: 20vh;
    left: 25vw;
    z-index: 1;
}

.user-list {
    margin-bottom: 2em;
    list-style-position: inside;
    padding-left: 0;
    max-height: 5em;
    overflow: scroll;
    border: 1px solid;
    /* padding: 10px; */
    box-shadow: 5px 10px;
}
</style>
