import { createRouter, createWebHistory } from 'vue-router'
// Vue Views
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import TestView from '../views/TestView.vue'
import ListView from '../views/ListView.vue'
import ResultsView from '../views/ResultsView.vue'
import UserAddView from '../views/UserAddView.vue'
import CreateTestView from '../views/CreateTestView.vue'
import AssignTestView from '../views/AssignTestView.vue'
import ProgressView from '../views/ProgressView.vue'
import NotFoundView from '../views/NotFoundView.vue'
// Pinia Store
import { testListStore } from '../stores/store'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/results',
    name: 'results',
    component: ResultsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/list',
    name: 'list',
    component: ListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: UserAddView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/createtest',
    name: 'createtest',
    component: CreateTestView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/assigntest',
    name: 'assigntest',
    component: AssignTestView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/progress',
    name: 'progress',
    component: ProgressView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  const testList = testListStore()
  if (to.meta.requiresAuth && testList.token==null) {
    return { name: 'home'}
  }
})

export default router
