import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import VueBasicAlert from 'vue-basic-alert'
import Vue3EasyDataTable from 'vue3-easy-data-table';

import 'vue3-easy-data-table/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap'
import './assets/main.css'
import 'v-calendar/dist/style.css';

const app = createApp(App)

app.component('EasyDataTable', Vue3EasyDataTable);
app.use(createPinia())
app.use(router)
app.use(VueBasicAlert)
app.mount('#app')

