<template>
    <div 
        class="row test-row justify-content-center" 
        data-test="test"
    >
        <div
            v-if="!disableButtons"
            class="col card results bg-light"
            :title="`This is test information for: '${test_name}'`"
            :style="{
                border: determineEmptyColour(test_status)
            }"
        >
            <b v-if="test_status === -1">
                <i>
                    <div class="row" 
                    >
                        <ListContent 
                            :test_status="test_status"
                            :test_name="test_name"
                            :test_due="test_due"
                            :test_progress="test_progress"
                            :test_info="test_info"
                            @redirectToTest="redirectTest(test_id, true)"
                        />
                    </div>
                </i>
            </b>
            <div
                v-else-if="test_status === 0 | test_status === 1"
                class="row" 
            >
                <ListContent 
                    :test_status="test_status"
                    :test_name="test_name"
                    :test_due="test_due"
                    :test_progress="test_progress"
                    :test_info="test_info"
                    :real_test_id="real_test_id"
                    @redirectToTest="redirectTest(test_id)"
                />
            </div>

        </div>
        <div 
            v-else-if="disableButtons"
            class="col card results bg-light"
            :style="{
                border: determineEmptyColour(test_status)
            }"
        >
            <div class="row">
                <ListContent 
                    :test_status="test_status"
                    :test_name="test_name"
                    :test_due="test_due"
                    :test_progress="test_progress"
                    :test_info="test_info"
                    :results="results"
                    @redirectToResults="redirectResults(test_id)" 
                />
            </div>
        </div>
    </div>
</template>

<script setup>
  import { testListStore } from '../stores/store'
</script>

<script>
    import "vue3-circle-progress/dist/circle-progress.css";
    import ListContent from './ListContent.vue';
    import axios from 'axios';

    export default {
        name: 'TestList',
        components: {
            ListContent
        },
        props: {
            test_name: {
                type: String
            },
            test_status: {
                type: Number
            },
            test_due: {
                type: String
            },
            test_progress: {
                type: Array,
                default() {
                    return [];
                }
            },
            test_new: { // Flag for new tests
                type: Boolean,
                default: false
            },
            test_id: {
                // test._id for NOT STARTED tests
                // session_id for STARTED tests
                type: String
            },
            test_info: {
                type: String
            },
            index: {
                type: Number
            },
            disableButtons: {
                type: Boolean,
                default: false
            },
            real_test_id: {
                // test._id for a given test, NOT THE SESSION ID
                type: String
            }
        },
        data() {
            return {
                active: false,
                thistest: 'prevent',
                testList: testListStore(),
                results: ""
            }
        },
        methods: {
            determineEmptyColour(status) {
                if (status === 2) {
                    return "3px solid #35db61"
                } else if (status === -1) {
                    return "3px solid #cfcfcf"
                } else {
                    return "3px solid #9cc2ff"
                }
            },
            redirectTest(test_id, test_new=false) {
                this.testList.$patch({
                    selectedTest: test_id
                });

                if (test_new) {
                    this.testList.$patch({
                        selectedTestNew: test_new
                    });
                }
                this.$router.replace({path:'/test'});
            },
            redirectResults(test_id) {
                this.testList.$patch({
                    selectedTest: test_id
                });
                this.$router.push('results/');
            },
        },
        created(){
            if (this.test_status==2){
                axios.get(`${process.env.VUE_APP_API}/session/results/${this.test_id}`,{withCredentials:true})
                    .then(results => {
                        // Save results data
                        let testResult = results.data
                        let len = Object.keys(testResult).length

                        // Generate overall score
                        let total_score = 0

                        for (var i = 0; i < len; i++) {
                            let result = testResult[i.toString()]
                            total_score += result.score
                        }

                        let overall_score = total_score*100/len

                        this.results = String(overall_score.toFixed(2))
                    })
            }
            
        },
    }
</script>

<style scoped lang="scss">
    .test-row {
        margin: 2vh auto;
        padding: 0 5vh;
        position: relative;
        max-width: 2000px;

        .card {
            border: none;
            border-radius: 10px;
            padding-top: 20px;
            padding-left: 20px;
            padding-bottom: 5px;
        }

        .button-col {
            padding-left: 40px;
        }
    }
</style>