<template>
    <div class="page">
        <div v-if="testList.permissions.includes('admin:write')">
            <AdminDashboard />
        </div>
        <div v-else>
            <UserDashboard />
        </div>
    </div>
</template>

<script setup>
import { testListStore } from '../stores/store'
let testList = testListStore()
</script>

<script>
import AdminDashboard from '../components/AdminDashboard.vue'
import UserDashboard from '../components/UserDashboard.vue'

export default {
    name: 'DashboardPage',
    components: {
        AdminDashboard,
        UserDashboard
    }
}
</script>

<style scoped lang="scss">
.page {
    padding-bottom: 50px;
}
</style>