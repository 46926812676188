<template>
    <div class="page container" style="margin: 0; width: 100%;">
        <loading 
            v-model:active="progressIsLoading"
            :can-cancel="false"
            :is-full-page="false"
            :loader="'dots'"
            :color="'#ff0000'"
            :height="200"
            :width="200"
        />
        <div class="row">
            <CustomSidebar 
                class="col-2"
                style="padding: 0"
                :menu="menu"
                :home="menuHome"
                :header="'User Test Navigation'"
                :current="currentIndex"
                @navigation="onItemClick"
            />

            <div class="col-10" style="min-height: 92vh;">
                <div class="container">
                    <div v-if="currentIndex === 0" class="col" style="text-align: center;">
                        <h1 class="override-h1">
                            {{ testName }}
                        </h1>

                        <div class="card bg-light">
                            <h5>Overall User Performance</h5>
                            <div class="row justify-content-evenly" style="padding-top: 1em;">
                                <div class="col">
                                    <h5>
                                        Average User Score
                                    </h5>
                                    <p>
                                        {{ isNaN(overallAvgScore)?'No Average Score (No Completed Attempts)':`${(overallAvgScore*100).toFixed(2)}%` }}
                                    </p>
                                </div>
                                <div class="col">
                                    <h5>
                                        Average Completion Time
                                    </h5>
                                    <p>
                                        {{ isNaN(overallAvgTime)?'No Average Time (No Completed Attempts)':`${toHoursAndMinutes(overallAvgTime)}` }}
                                    </p>
                                </div>
                            </div>
                            
                            <h5 style="padding-top: 1em;">Overall User Progress</h5>
                            <p>Total Participants: {{ userList.length }}</p>
                            <p>Total Number Tests Attempts: {{ totalAssigned }}</p> 
                            <p>Total Completed Tests: {{ totalCompleted }}</p>
                            <p style="padding-top: 1em;">
                                <button type="button" class="btn btn-primary" @click="downloadTestResults(userList)" :disabled="totalCompleted==0"><span class="bi-file-earmark-arrow-down-fill"></span> Download All Results</button>
                            </p>
                        </div>
                        <div v-if="totalCompleted>0" class="card bg-light" style="width: 50%; margin: 0 auto;">
                            <h5>All User Results</h5>
                            <EasyDataTable
                                :headers="overallTableHeaders"
                                :items="overallUserItems"
                                :alternating="true"
                                :rows-per-page="rowsPerPage"
                            /> 
                        </div>
                        <div v-if="totalCompleted>0" class="card bg-light" style="height: auto;">
                            <h5>Average Question Score 
                                <span class="bi-info-circle-fill info" :title="'The colour of the bars are determined based on the following:\n - Green if the users averaged 100% \n - Orange if the users averaged 99%-50% \n - Red if the users averaged below 50% \nIf all users got a question wrong then the given bar will not be visible.'"></span>
                            </h5>
                            <GenericChart
                                :plotType="'bar'"
                                :data="{
                                    labels: questionChart.x_axis,
                                    datasets: [{
                                        axis: 'y',
                                        data:  questionChart.y_axis,
                                        backgroundColor: questionChart.colour,
                                        borderWidth: 1
                                    }]
                                }"
                                :options="{
                                    animation: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        }
                                    },
                                    scales: {
                                        y: {
                                            type: 'linear',
                                            max: 100,
                                            min: 0,
                                            ticks: {
                                                stepSize: 20,
                                                callback: (value) => { return `${value}%` }
                                            }
                                        },
                                        x: {
                                            beginAtZero: true
                                        }
                                    }
                                }"
                                :dynamicID="'canvas'"
                            />
                        </div>
                        <div v-if="totalCompleted>0" class="card bg-light" style="height: auto;">
                            <h5>Average Question Completion Time
                                <span class="bi-info-circle-fill info" :title="'Displays the average question completion time across all user question attempts.'"></span>
                            </h5>
                            <GenericChart
                                :plotType="'line'"
                                :data="{
                                    labels: questionChart2.x_axis,
                                    datasets: [{
                                        axis: 'y',
                                        data:  questionChart2.y_axis,
                                        backgroundColor: questionChart2.colour,
                                        borderColor: questionChart2.line,
                                        borderWidth: 1,
                                        fill: {
                                            target: 'origin',
                                            above: questionChart2.fill
                                        }
                                    }]
                                }"
                                :options="{
                                    animation: false,
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        }
                                    },
                                    scales: {
                                        y: {
                                            ticks: {
                                                callback: (value) => { return `${value}s` }
                                            }
                                        },
                                        x: {
                                            beginAtZero: true
                                        }
                                    }
                                }"
                                :dynamicID="'canvas2'"
                            />
                        </div>
                    </div>
                    <div v-else>
                        <h1>Results for {{ currentUser }} </h1>
                        <h3>{{ testName }}</h3>

                        <div class="card bg-light">
                            <h5>Overall Progress Information</h5>
                            <p>Number of Test Attempts: {{ organisedProgress[currentUser].length }}</p>
                            <p>Number of Completed Attempts: {{ organisedProgress[currentUser].filter(progress => progress.status === 2).length }}</p>
                            <p style="padding-top: 1em;">
                                <button type="button" class="btn btn-primary" @click="downloadTestResults([currentUser])" :disabled="userResults.length==0"><span class="bi-file-earmark-arrow-down-fill"></span> Download {{ currentUser }}'s Results</button>
                            </p>
                        </div>

                        <div v-if="userResults.length > 1" class="card bg-light">
                            <h5>All of {{ currentUser }}'s Results</h5>
                            <EasyDataTable
                                :headers="userTableHeaders"
                                :items="userTableItems"
                                :alternating="true"
                                :rows-per-page="rowsPerPage"
                            /> 
                        </div>
                        
                        <div v-else-if="userResults.length == 1" class="card bg-light">
                            <div class="row justify-content-evenly">
                                <div class="col">
                                    <h5>Score</h5>
                                    <p>{{ (userResults[0].overall*100).toFixed(2) }}%</p>
                                </div>
                                <div class="col">
                                    <h5>Completion Time</h5>
                                    <p>{{ toHoursAndMinutes(userResults[0].total_elapsed) }}</p>
                                </div>
                                <div class="col">
                                    <h5>Submitted</h5>
                                    <p>{{ new Date(userResults[0].session_end).toDateString() }}</p>
                                </div>
                            </div>
                        </div>

                        <div v-if="userResults.length > 0" class="question-view" >
                            <div v-if="userResults.length > 1" class="card bg-light">
                                <h5>Choose User Attempt to View</h5>
                                <VueMultiselect v-model="currentTest" :options="attemptList" 
                                    :multiple="false" :close-on-select="true" @select="scrollToElement({behavior: 'smooth'})" />
                            </div>
                            <div v-if="userResults.length == 1 || currentTest != null">
                                <hr style="width: 50%; margin: 1em auto;" />
                                <h5 style="margin: 1em 0;"><i>Viewing {{ currentUser }}'s Answers</i></h5>
                                <QuestionResults :test_name="''" :pagination="true" :user_name="`${currentUser}'s`"
                                    :current_question="currentUserQuestion" :given_results="sessionsInfo[userResults[currentTest==null?0:attemptList.findIndex(element=>element==currentTest)].session_id]" 
                                    :given_completed="organisedProgress[currentUser].find(result => result._id == userResults[currentTest==null?0:attemptList.findIndex(element=>element==currentTest)].session_id)['progress']"
                                    />
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { testListStore } from '../stores/store.js';
</script>

<script>
    import axios from 'axios'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/css/index.css'
    import CustomSidebar from '../components/CustomSidebar.vue'
    import 'vue-json-pretty/lib/styles.css'
    import QuestionResults from "../components/result/QuestionResults.vue";
    import GenericChart from '../components/GenericChart.vue'
    import 'chart.js/auto';
    import VueMultiselect from 'vue-multiselect'
    import 'vue-multiselect/dist/vue-multiselect.css'

    export default {
        name: 'ProgressPage',
        order: 1,
        components: {
            Loading,
            CustomSidebar,
            GenericChart,
            QuestionResults,
            VueMultiselect
        },
        data() {
            return {
                progressIsLoading: true,
                testList: testListStore(), // 'testList.selectedTest' => testID
                menuHome: {
                    title: 'Overall User Results',
                    index: 0,
                    icon: 'bi-house-fill'
                },
                testName: 'Overall User Results', // If getting the test name fails
                menu: [],
                currentIndex: 0,
                currentUser: null,
                organisedProgress: {},
                userList: [],
                overallAvgScore: null,
                overallAvgTime: null,
                totalAssigned: 0,
                totalCompleted: 0,
                fullResults: [],
                userResults: [],
                overallTableHeaders: [
                    {"text": "User", "value":"test_taker" },
                    {"text": "Attempt", "value":"attempt" },
                    {"text": "Start", "value": "session_start" },
                    {"text": "Submission", "value": "session_end" },
                    {"text": "Overall Score", "value": "overall" },
                    {"text": "Completion Time", "value": "total_elapsed" }
                ],
                overallUserItems: [],
                userTableHeaders: [
                    {"text": "Attempt", "value":"attempt" },
                    {"text": "Start", "value": "session_start" },
                    {"text": "Submission", "value": "session_end" },
                    {"text": "Overall Score", "value": "overall" },
                    {"text": "Completion Time", "value": "total_elapsed" }
                ],
                userTableItems: [],
                rowsPerPage: 5,
                currentUserQuestion: 1,
                questionTotal: 0,
                attemptList: [],
                userSession: null,
                sessionsInfo: {},
                questionChart: {},
                questionChart2: {},
                currentTest: null
            }
        },
        methods: {
            onItemClick(item){
                this.currentIndex = item
                if (item.index === 0) {
                    this.currentUser = null
                } else {
                    this.currentUser = this.userList[item-1]
                }
            },
            setUpProgressPage() {
                const chosen_test = this.testList.managedTests[this.testList.managedTests.findIndex((test) => test['_id'] === this.testList.selectedTest)]
                this.testName = chosen_test['name']
                this.getTestProgress(chosen_test['order'])
                this.getTestReport(chosen_test)
            },
            setUpSidebar(users) {
                users.forEach((value, i) => {
                    this.menu.push(
                        {
                            title: `${value}`,
                            index: i+1,
                            icon: 'bi-person-fill'
                        }
                    )
                });
            },
            downloadTestResults(chosen_users) {
                axios(
                    {
                        method: 'post',
                        url: `${process.env.VUE_APP_API}/manage/report`,
                        data: {
                            "users":chosen_users,
                            "tests":[this.testList.selectedTest],
                            "type":"xlsx"
                        },
                        withCredentials: true,
                        responseType: 'blob'
                    }
                )
                    .then(results => {
                        const anchor = document.createElement('a');
                        anchor.href = window.URL.createObjectURL(results.data);
                        anchor.target = '_blank'
                        anchor.download = 'OverallResults.xlsx';
                        anchor.click();
                    })
            },
            organiseUserInfo(localUserList, localTestProgress) {
                localUserList.sort()
                localUserList.forEach((username) => {
                    this.organisedProgress[username] = localTestProgress.filter(test => test.owner === username)
                })
                this.setUpSidebar(localUserList)
                this.userList = localUserList
                this.progressIsLoading = false
            },
            getTestProgress(questionOrder) {
                axios.get(
                    `${process.env.VUE_APP_API}/manage/progress/?test_id=${this.testList.selectedTest}`,
                    {
                        withCredentials: true
                    }
                )
                    .then(results => {
                        const localTestProgress = results.data
                        this.questionTimeChart(localTestProgress, questionOrder)
                        this.totalAssigned = localTestProgress.length
                        this.totalCompleted = localTestProgress.filter(progress => progress.status === 2).length
                        localTestProgress.forEach(session => this.calculateScoreTotals(session._id))
                        const reorderedTestProgress = this.reorganiseSessionProgress(localTestProgress, questionOrder)
                        axios.get(`${process.env.VUE_APP_API}/manage/assigned/${this.testList.selectedTest}`, { withCredentials: true })
                            .then(results => {
                                // Get all assigned users from API
                                const localUserList = results.data
                                this.organiseUserInfo(localUserList, reorderedTestProgress)
                            })
                            .catch(() => {
                                // If cannot get assigned users, get all unique users from testProgress
                                const localUserList = reorderedTestProgress
                                    .map(x => x.owner)
                                    .filter((value, index, array) => array.indexOf(value) === index) 
                                this.organiseUserInfo(localUserList, reorderedTestProgress)
                            })
                        
                        
                    })
                    .catch(() => {
                        this.totalAssigned = 0
                        this.totalCompleted = 0
                        axios.get(`${process.env.VUE_APP_API}/manage/assigned/${this.testList.selectedTest}`, { withCredentials: true })
                            .then(results => {
                                this.organiseUserInfo(results.data, [])
                            })
                            .catch(() => {
                                this.setUpSidebar([])
                                this.userList = []
                            })
                        this.progressIsLoading = false
                    })
            },
            reorganiseSessionProgress(localTestProgress, questionOrder){
                const fixedTestProgress = []
                const correct_qid_order = Array.apply(null, Array(questionOrder.length)).map(function () { })
                questionOrder.forEach(question => {
                    correct_qid_order[question.index] = question.question
                })

                localTestProgress.forEach(session => {
                    const reordered_progress = []
                    correct_qid_order.forEach(qid => { // Reorder the data within state to match the order it is returned from result.
                        let new_index = session['progress'].findIndex(question => question.question == qid)
                        reordered_progress.push(session['progress'][new_index])
                    })
                    session.progress = reordered_progress
                    fixedTestProgress.push(session)
                })
                return fixedTestProgress
            },
            getTestReport(test_info) {
                axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_API}/manage/report`,
                    data: {
                        "users":this.userList,
                        "tests":[this.testList.selectedTest],
                        "type":"json"
                    },
                    withCredentials: true,
                })
                    .then(results => {
                        this.fullResults = results.data
                        this.overallAvgScore = (this.fullResults.map(test => test.overall)
                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0))/this.fullResults.length
                        this.overallAvgTime = (this.fullResults.map(test => test.total_elapsed)
                            .reduce((accumulator, currentValue) => accumulator + currentValue, 0))/this.fullResults.length
                        const table_content = []
                        this.fullResults.forEach((attempt) => {
                            const current_attempt_amount = table_content
                                .filter(content=>content.test_taker==attempt.test_taker).length
                            
                            let user_row = {
                                'test_taker': attempt.test_taker,
                                'attempt': `Attempt ${ current_attempt_amount==0?1:current_attempt_amount+1 }`,
                                'session_start': new Date(attempt.session_start).toLocaleDateString(),
                                'session_end': new Date(attempt.session_start).toLocaleDateString(),
                                'overall': `${(attempt.overall*100).toFixed(2)}%`,
                                'total_elapsed': this.toHoursAndMinutes(attempt.total_elapsed),
                                'session_id': attempt.session_id
                            }
                            table_content.push(user_row)
                        })
                        this.overallUserItems = table_content
                        this.questionScoreChart(test_info, this.fullResults)
                    })
                    .catch(() => {
                        this.fullResults = []
                    })
            },
            questionScoreChart(test_info, full_results){
                const x_axis = []
                const y_axis = []
                const bar_colour = []
                test_info.questions.forEach((question, index) => {
                    const current_question = index+1
                    x_axis.push(`Question ${current_question}`)
                    const filtered_question_results = full_results.map(results => results[`question_${current_question}`])
                    const current_question_total = filtered_question_results.reduce((accumulator, currentValue) => accumulator + (currentValue*100), 0)
                    const question_avg = current_question_total/filtered_question_results.length
                    y_axis.push(question_avg)
                    if (question_avg==100) {
                        bar_colour.push('#06D6A0')
                    } else if (question_avg<50) {
                        bar_colour.push('#EF476F')
                    } else {
                        bar_colour.push('orange')
                    }
                })
                this.questionTotal = x_axis.length
                this.questionChart = {
                    "x_axis": x_axis,
                    "y_axis": y_axis,
                    "colour": bar_colour
                }
            },
            questionTimeChart(progress_info, question_order){
                const x_axis = []
                const y_axis = []

                const avg_question_elapsed = {}
                question_order
                    .map(order => order.question)
                    .forEach(question_id => {
                        const all_question_times = progress_info
                            .map(attempt => attempt.progress.filter(question_info => question_info.question == question_id))
                            .map(attempt => attempt[0].elapse)
                        avg_question_elapsed[question_id] = (all_question_times.reduce((accumulator, currentValue) => accumulator + currentValue, 0))/all_question_times.length
                    })
                
                for (let i = 0; i < question_order.length; i++) {
                    x_axis.push(`Question ${i+1}`)
                    y_axis.push(avg_question_elapsed[question_order.find(question => question.index == i).question])
                }

                this.questionChart2 = {
                    "x_axis": x_axis,
                    "y_axis": y_axis,
                    "colour": "#0077b6",
                    "line": "#00b4d8",
                    "fill": "rgba(202, 240, 248, 0.4)"
                }
            },
            scrollToElement(options) {
                setTimeout(
                    () => {
                        const el = this.$el.getElementsByClassName('question-view')[0];
                        if (el) {
                            el.scrollIntoView(options);
                        }
                    }, 
                    600
                );  
                
            },
            toHoursAndMinutes(totalSeconds) {
                const totalMinutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;
                return `${hours}h  ${minutes}m  ${Math.floor(seconds)}s`
            },
            calculateScoreTotals(session_id) {
                axios.get(`${process.env.VUE_APP_API}/session/results/${session_id}`,{withCredentials:true})
                    .then(results => {
                        // Save results data
                        let testResult = results.data

                        // Generate overall score
                        let total_tp = 0
                        let total_tn = 0
                        let total_fp = 0
                        let total_fn = 0
                        let total_ic_fp = 0
                        let total_ic_fn = 0
                        let total_score = 0
                        let all_results = []
                        let len = Object.keys(testResult).length
                        
                        for (var i = 0; i < len; i++) {
                            let result = testResult[i.toString()]
                            all_results.push(result)
                            
                            total_tp += result["tp_count"]
                            total_tn += result["tn_count"]
                            total_fp += result["fp_count"]
                            total_fn += result["fn_count"]

                            if ("ic_fp_count" in result) {
                                total_ic_fp += result["ic_fp_count"]
                            }

                            if ("ic_fn_count" in result) {
                                total_ic_fn += result["ic_fn_count"]
                            }

                            total_score += result["score"]
                        }

                        // const correct_qid_order = all_results.map(result => result.question) // Get new question order based on result's order
                        // const reordered_progress = Array.apply(null, Array(correct_qid_order.length)).map(function () { }) 
                        // correct_qid_order.forEach((qid, index) => { // Reorder the data within state to match the order it is returned from result.
                        //     let new_index = this.testList.completed[this.givenTestIndex]['progress'].findIndex(question => question.question == qid)
                        //     reordered_progress[index] = this.testList.completed[this.givenTestIndex]['progress'][new_index]
                        // })
                        // this.testList.completed[this.givenTestIndex]['progress'] = reordered_progress
                        
                        all_results.unshift({
                            'tp_count': total_tp,
                            'tn_count': total_tn,
                            'fp_count': total_fp,
                            'fn_count': total_fn,
                            'ic_fp_count': total_ic_fp,
                            'ic_fn_count': total_ic_fn,
                            'score': total_score/len
                        })

                        this.sessionsInfo[session_id] = all_results
                    })
                    .catch(() => {})
            }
        },
        watch: {
            currentUser(newUser) {        
                if (newUser != null || newUser != undefined) {
                    this.userResults = this.fullResults.filter(result => result.test_taker == newUser)
                    const givenUserResults = this.userResults
                    this.currentTest = null
                    if (this.userResults.length > 0) {
                        const table_content = []
                        givenUserResults.forEach((attempt, item) => {
                            let user_row = {
                                'attempt': `Attempt ${item+1}`,
                                'session_start': new Date(attempt.session_start).toLocaleDateString(),
                                'session_end': new Date(attempt.session_end).toLocaleDateString(),
                                'overall': `${(attempt.overall*100).toFixed(2)}%`,
                                'total_elapsed': this.toHoursAndMinutes(attempt.total_elapsed),
                            }
                            table_content.push(user_row)
                        })
                        this.userTableItems = table_content
                        this.attemptList = table_content.map(content => content.attempt)
                    }
                }
            }
        },
        computed: {

        },
        beforeCreate() {
            
        },
        created() {
            
        },
        mounted() {
            this.setUpProgressPage()
        }
    }
</script>

<style lang="scss" scoped>
    .override-h1 {
        margin-bottom: 0;
    }

    .card {
        border: none;
        padding: 30px;
        width: 50%;
        margin: 30px auto;
    }

    h5 {
        font-weight: bold;
        padding-bottom: 5px;
    }

    p {
        margin: 0 0 2px 0;
    }

    .btn {
        margin-top: 5px;
    }

    .container {
        max-width: 100vw;
    }

    .flex-container {
        display: flex;
    }

    .small-paragraph {
        margin-top:1em; 
        font-size: 14px;
        font-style: italic;
    }
    .info {
        cursor: pointer;
    }

    .info:hover{
        color: #34afed;
    }

    .vue3-easy-data-table {
        z-index: 2; // To prevent the 'row per page' dropdown overlap with '.card'
    }
</style>