<script setup>
  import NavBar from "./components/Nav.vue"
</script>

<template>
  <!-- Noto -->
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,500;0,700;1,500;1,700&display=swap" rel="stylesheet">

  <NavBar v-if="$route.name!='home'" />
  <router-view/>
</template>

<script>
  export default {
    name: 'app',
    components: {
    },
    methods: {
    }
  };
</script>

<style lang="scss">

#app {
  font-family: 'Noto Sans', sans-serif;
  text-align: center;
}
</style>
