<template>
  <div>
    <canvas :id="dynamicID" :style="canvasStyle"></canvas>
  </div>
</template>
<script>

import Chart from 'chart.js/auto';

export default {
  name: 'ChartJs',
  props: {
    plotType: String,
    data: Object,
    options: Object,
    dynamicID: String,
    canvasStyle: Object
  },
  mounted() {
    const chrt = document.getElementById(this.dynamicID);
    this.chart = new Chart(chrt, {
      type: this.plotType,
      data: this.data,
      options: this.options
    });
    this.chart;
    
  },
  updated() {
    this.chart.destroy(); // Destroy original
    const chrt = document.getElementById(this.dynamicID); // Create new
    this.chart = new Chart(chrt, {
      type: this.plotType,
      data: this.data,
      options: this.options
    });
    this.chart;
  },
  unmounted() {
    this.chart.destroy();
  }
}
</script>
<style scoped>

</style>