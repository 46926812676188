<template>
    <Transition name="high" appear>
        <h1>All Owned Tests <span class="bi-info-circle-fill" :title="legend_content"></span></h1>
    </Transition>

    <Transition name="medium" appear>
        <div class="row justify-content-center">
            <div v-if="managed_tests.length === 0">
                <p style="margin-top: 20px;">
                    You have no owned tests.
                </p>
            </div>
            <div v-for="[index, test] in managed_tests.entries()" :key="`${index} test`"
                class="col-2 admin-tests bg-light">
                <h2>{{ test.name }}</h2>
                <div class="circle-container">
                    <circle-progress :percent="(test['total-assigned'] == 0) ? 0 : ((test['total-completed'] / test['total-assigned']) * 100)" :border-width="7" :border-bg-width="7" :transition="800" :viewport="true"
                        :fill-color="determineFill((test['total-assigned'] == 0) ? 0 : ((test['total-completed'] / test['total-assigned']) * 100))" :size="50"
                        :empty-color="determineEmpty(test['total-assigned'], (test['total-assigned'] == 0) ? 0 : ((test['total-completed'] / test['total-assigned']) * 100))" class="center" />
                </div>
                <div class="created">
                    <p>Assigned: {{ test['total-assigned'] }}</p>
                    <p style="margin-bottom: 0.5em;">Completion: {{ (test['total-assigned'] == 0) ? 0 : ((test['total-completed'] / test['total-assigned']) * 100).toFixed(2) }}%</p>
                    <p>Created: {{ new Date(test.modified).toLocaleDateString() }}</p>
                </div>

                <div>
                    <button type="button" class="btn justify-content-center"
                        :class="determineButton(test['total-assigned'], test['total-completed'])"
                        @click="$emit('redirect', test['_id'])">
                        Review
                    </button> 
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
    import CircleProgress from "../components/CircleProgress.vue"
    export default {
        name: 'OwnedTests',
        emits: [ 'redirect' ],
        components: {
            CircleProgress
        },
        props: {
            managed_tests: {
                type: Array,
                default() {
                    return [];
                }
            },
        },
        data() {
            return {
                progress: 0,
                legend_content: 'The colour of test is determined based on the following:\n - Green: all assigned users have completed their attempts \n - Blue: not all assigned users have completed their attempt \n - Yellow: no users have been assigned the given test'
            }
        },
        methods: {
            determineButton(assigned, completed) {
                if (assigned == 0) {
                    return ['btn-warning', 'text']
                } else if ((completed / assigned) * 100 >= 100)
                    return ['btn-success']
                else {
                    return ['btn-primary']
                }
            },
            determineEmpty(assign, prog) {
                if (assign == 0) {
                    return '#ffefbd'
                } else if (prog >= 100) {
                    return '#35db61'
                } else {
                    return '#9cc2ff'
                }
            },
            determineFill(prog) {
                if (prog >= 100)
                    return '#35db61'
                else {
                    return "#3d44ff"
                }
            }
        }
    };
</script>

<style scoped lang="scss">

.admin-tests {
    border: none;
    border-radius: 10px;
    height: 275px;
    margin: 20px 20px 30px 20px;
    padding: 1em;
    width: 300px;
    overflow: auto;

    .created{

        margin-bottom: 0.75em;

        p {
            font-style: italic;
            margin-bottom: 0;
            font-size: 15px;
            color: hsl(0, 0%, 35%)
        }
    }
    

    h2 {
        font-size: 20px;
        font-weight: bold;
    }
}

.row {
    padding: 0 20px 0 20px;
}
.circle-container {
    margin: 1em 0 0.75em 0;
    height: 20%;
}

.bi-info-circle-fill {
    cursor: pointer;
}

.bi-info-circle-fill:hover {
    color: #34afed;
}

</style>