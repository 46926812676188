<template>
    <div class="col inspect">
        <ViewerTest
            ref="viewer"
            :options="first_options"
            :images="probe"
            class="viewer"
            @inited="inited"
        >
            <template #default="scope">
                <div hidden> 
                    <!-- TODO - Better fix -->
                    <img
                        v-for="id in scope.images"
                        class="image"
                        :key="id.id"
                        :src="`${baseImageURL}${id}`"
                        :data-source="`${baseImageURL}${id}`"
                        :alt="'Probe'"
                        style="width: 100%"
                    >
                </div> 
            </template>
        </ViewerTest>
    </div>

    <div 
        class="col inspect" 
        :style="{
            backgroundColor: currentColour
        }"
    >
        <!-- style="background-color: green" -->
        <ViewerTest
            ref="secondviewer"
            :options="second_options"
            :images="candidates"
            :emit_index="true"
            class="viewer"
            @inited="secondinited"
            @indexInstance="indexInstance"
        > 
            <template #default="scope">
                <!-- TODO - Better fix -->
                <div hidden>
                    <img
                        v-for="id in scope.images"
                        class="image"
                        :key="`${id}-image-compare`"
                        :src="`${baseImageURL}${id}`"
                        :data-source="`${baseImageURL}${id}`"
                        :alt="'Candidate'"
                        style="width: 100%"
                    >
                </div>
            </template>
        </ViewerTest>                
    </div>
</template>
<script>
    import ViewerTest from "../components/ViewerComp.vue"

    export default {
        name: 'ComparisonView',
        expose: ['pushCandidateViewer'],
        components: {
            ViewerTest,
        },
        props: {
            probe: Array,
            candidates: Array,
            baseImageURL: String
        },
        data() {
            return {
                currentColour: '',
                first_options: {
                    inline: true,
                    button: false,
                    navbar: false,
                    title: false,
                    toolbar: {
                        oneToOne: true,
                        leftRotate: () => {
                            this.$viewer.rotate(10);
                        },
                        rightRotate: () => {
                            this.$viewer.rotate(-10);
                        },
                        reset: true,
                        customZoom:() => {
                            this.$viewer.zoom(1)
                        }
                    },
                    tooltip: true,
                    movable: true,
                    zoomable: true,
                    rotatable: true,
                    scalable: true,
                    transition: false,
                    fullscreen: false,
                    keyboard: true,
                    url: 'data-source'
                },
                second_options: {
                    inline: true,
                    button: false,
                    navbar: false,
                    title: false,
                    toolbar: {
                        oneToOne: true,
                        leftRotate: () => {
                            this.$secondviewer.rotate(10);
                        },
                        rightRotate: () => {
                            this.$secondviewer.rotate(-10);
                        },
                        reset: true,
                        customZoom:() => {
                            this.$secondviewer.zoom(1)
                        }
                    },
                    tooltip: true,
                    movable: true,
                    zoomable: true,
                    rotatable: true,
                    scalable: true,
                    transition: false,
                    fullscreen: false,
                    keyboard: true,
                    url: 'data-source',
                }
            }
        },
        methods: {
            inited(viewer) {
                this.$viewer = viewer
            },
            secondinited(viewer) {
                this.$secondviewer = viewer
            },
            indexInstance(index){
                this.inspectImageIndex = index
            },
            pushCandidateViewer(whichList) {
                if(whichList === 'match') {
                    this.currentColour='#02f542'
                } else if (whichList === 'nonmatch') {
                    this.currentColour='#a0b6ca'
                } else {
                    this.currentColour='#f4a460'
                }
                setTimeout(
                    () => {
                        this.currentColour='';
                        this.$secondviewer.next();
                    }, 
                    600
                );  
                
            }
            
        },
    }
</script>

<style scoped>
.inspect {
    height: 60vh;
    margin: 1em 1em 1em 1em;
    margin-top: 0;
}

.image {
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5);
}
</style>